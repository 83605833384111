import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { cmsDeleteAccount, cmsGet } from '../CallMSAPI.js';
import { canAccess } from '../CallMSUIHelpers.js';
// required for show/hide delete button...
import { getMsalConfig } from '../MSALAuthProvider';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import ExpandingReactTable from '../ExpandingReactTable';
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import AccountRow from './AccountRow';
import { connect } from 'react-redux';
var _ = require('lodash');

class AccountsReactTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            canDelete: getMsalConfig().app.allowDeleteAccount,
            filtered: [],

            expanded: {},
            hasRowExpanded: false,
            unsavedChanges: false,
        }
        this.tableRef = React.createRef();
        this.updateData = this.updateData.bind(this);
        this.deleteAccountClickHandler = this.deleteAccountClickHandler.bind(this);
        this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
        this.clearChanges = this.clearChanges.bind(this);
        this.closeAllRows = this.closeAllRows.bind(this);
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.account.Id !== this.props.account.Id) {
            this.setState({ page: 0, data: [], pageCount: -1, loading: true }, function () {
                self.tableRef.current.refReactTable.state.page = 0;
                self.tableRef.current.forceTableUpdate();
            });
            return;
        }

        if ( prevProps.lastUpdate !== this.props.lastUpdate ) {
            if(showConsoleLogs()){
                console.log("Starting an update, lastUpdate mismatch");
            }
            self.tableRef.current.forceTableUpdate();
        }

        if (prevProps.location.pathname != this.props.location.pathname
            && self.state.filtered != []) {
            self.setState({filtered: []});
        }
    }

    updateData(state, instance) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        // Doesn't make sense to keep the row open when the data is changing underneath
        if (self.tableRef.current) {
            self.tableRef.current.closeAllRows();
        }
        self.closeAllRows()

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'accounts'
        };

        // Bail if we can't make the API call...
        if (apiParams.accountId === undefined) {
            return;
        }

        // Handle sorting/order
        //apiParams['SortBy'] = 'FirstName';
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'name') {
                apiParams['SortBy'] = 'Name';
            }
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Our version of normal search text...
                if (f.id === 'name') {
                    apiParams['searchText'] = f.value;
                    return;
                }
                if (f.id === 'externalid') {
                    apiParams['externalIdSearch'] = f.value;
                    return;
                }
            });
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGet(
            apiParams,
            function (callmsData) {
                self.setState({
                    data: callmsData.Results,
                    pageCount: callmsData.PageCount,
                    loading: false
                }, function () {
                    self.props.loadCompleteCallback && self.props.loadCompleteCallback();
                });
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    deleteAccountClickHandler(e, row) {
        var self = this;
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete account '" + row.Name + "'?")) {
            cmsDeleteAccount(
                row.Id,
                function (ok) {
                    toast.success("Account '" + row.Name + "' deleted OK");
                    self.tableRef.current.forceTableUpdate();
                },
                function (err) {
                    toast.error(err);
                }
            );
        }
    }

    goToAccounts = (e, id = '') => {
        e.preventDefault();
        this.props.history.push(this.accountsRoute(id));
    }

    accountsRoute = (id = '') => {
        return `/accounts/${id}`;
    }

    render() {
        var self = this;
        const data = this.state.data;

        return (
            <ExpandingReactTable
                data={data}
                updateData={self.updateData}
                pages={this.state.pageCount}
                resizable={false}
                filterable
                expanded={this.state.expanded}
                onExpandedChange={this.handleRowExpanded}
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            var prefix = null;
                            if (self.state.canDelete && d.NumChildren === 0) {
                                prefix = (
                                    <a href={`/accounts/${d.Id}`} className="btn btn-default btn-xs btn--domain-delete" onClick={(e) => self.deleteAccountClickHandler(e, d)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </a>
                                );
                            }
                            return (
                                <div className="account-tablecell">
                                    <div className="accountName-tablecell">{d.Name}</div>
                                    <div className="deleteAccount-tablecell">{prefix}</div>
                                </div>
                            );
                        }
                    },
                    {
                        id: "externalid",
                        Header: "Account Reference",
                        accessor: function (d) {
                            return (
                                <span>{d.ExternalId}</span>
                            );
                        },
                        sortable: false,
                        filterable: true
                    },
                    {
                        id: "selectaccount",
                        Header: "Account Link",
                        accessor: function (d) {
                            // Does the user have subscription contributor?
                            // _or_ are there children?
                            if (d.NumChildren === 0 && !canAccess('AccountPortal', self.props.baseAccountInfo.Roles)) {
                                // No link...
                                return null;
                            }

                            var prefix = null;
                            if (d.NumChildren > 0) {
                                prefix = <span>({d.NumChildren} Accounts)</span>
                            }
                            return (
                                <a href={self.accountsRoute(d.Id)} onClick={(e) => self.goToAccounts(e, d.Id)} to={"/accounts/" + d.Id}>
                                    <i className="fa-solid fa-list-check"></i> {prefix}
                                </a>
                            );
                        },
                        sortable: false,
                        filterable: false,
                        className: 'text-center'
                    },
                    {
                        id: "useaccount",
                        Header: "Portal Link",
                        accessor: function (d) {
                            return (<a href={"/portal/" + d.Id + "/users"} data-testid='customerPortalId'>Customer Portal</a>);
                        },
                        sortable: false,
                        filterable: false,
                        className: 'text-center'
                    }
                ]
                }
                defaultSorted={[{
                    id: 'name',
                    desc: false
                }]}
                minRows={0}
                showPageJump={false}
                multiSort={false}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"You don't have any sub accounts."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                filtered={this.state.filtered}
                onFilteredChange={(filtered) => {
                    var filterOb = _.cloneDeep(filtered);

                    for (var i = 0; i < filterOb.length; i++) {
                        if (filterOb[i].value.trim() === "") {
                            delete filtered[i].value
                        }
                        else {
                            filtered[i].value = filtered[i].value.replace(/^\s*/, '');
                        }
                    }
                    self.setState({ filtered: filtered });
                }}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                ref={this.tableRef}
                SubComponent={row => {
                    return (
                        <AccountRow
                            hasUnsavedChanges={self.state.unsavedChanges}
                            setUnsavedChanges={self.setUnsavedChanges}
                            clearChanges={self.clearChanges}
                            closeCallback={this.closeAllRows}
                            targetAccountOverview={row.original}
                        />
                    );
                }}
            />
        );
    }

    closeAllRows = (e) => {
        if (e) {
            e.preventDefault();
        }
        // Ditch all expanded rows...
        this.setState({
            expanded: {},
        });
    }

    setUnsavedChanges(val, cb) {
        this.setState({
            unsavedChanges: val
        }, function () {
            cb && cb();
        });
    }

    clearChanges(cb) {
        this.setState({
            unsavedChanges: false
        }, function () {
            cb && cb();
        });
    }

    handleRowExpanded = (newExpanded, index, event) => {
        if (this.state.unsavedChanges) {
            alert("You have unsaved changes. Please update or cancel these changes before continuing.");
            return;
        }

        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
                hasRowExpanded: true
            });

            // Mark a refresh in progress to stop updates
            this.props.setRefreshPause && this.props.setRefreshPause(true);
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsReactTable));

// required for JSX
import React, { Component, useState } from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Field } from 'formik';
import { getBaseUrl, cmsTempAuthRedirect } from './CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Modal, Toggle } from 'office-ui-fabric-react';
import FieldWithDisposition from './Services/FieldWithDisposition.js';
import { DialogFooter } from '@fluentui/react';
import CreatableSelect from 'react-select/creatable';
import { Roles } from './models/Enums.js';
import { generatePath } from "react-router";
import * as moment from "moment";

var _ = require('lodash');

require("moment-duration-format");
const XRegExp = require('xregexp');

export function getPbxLabel(account) {
    var text = 'N/A';
    var out = [];

    if (account.AvailableServices) {
        var pbxSer = _.find(account.AvailableServices, function (s) { return s.Code === 'pbx' });
        if (pbxSer) {
            if (_.find(pbxSer.Variants, function (v) { return v.Code === 'PBX' })) {
                out.push('PBX');
            }
            if (_.find(pbxSer.Variants, function (v) { return v.Code === 'TRUNK' })) {
                out.push('Trunk');
            }
        }
    }

    if (out.length) {
        text = out.join(" / ");
    }

    return text;
}

export function hasPbxVariantSupport(account, variantCode) {
    if (account.AvailableServices) {
        var pbxSer = _.find(account.AvailableServices, function (s) { return s.Code === 'pbx' });
        if (pbxSer) {
            if (_.find(pbxSer.Variants, function (v) { return v.Code === variantCode })) {
                return true;
            }
        }
    }

    return false;
}

export function canAccess(uiPart, roles) {

    // Helper strings...
    let accountOwner = 'Account Owner';
    let subContrib = 'Subscription Contributor';
    let subViewer = 'Subscription Viewer';
    let systemOwner = 'System Owner';
    let sbcViewer = 'SBC Viewer';
    switch (uiPart) {
        case 'BillSummary':
            return _.find(roles, { 'Name': systemOwner });
        case 'AccountAudit':
            return _.find(roles, { 'Name': systemOwner });
        case 'WizardDebug':
            return _.find(roles, { 'Name': systemOwner });
        case 'ServiceUserType':
            return _.find(roles, { 'Name': systemOwner });
        case 'IsBetaFeaturesEnabled':
            return _.find(roles, { 'Name': systemOwner });
        case 'ViewUpgrade':
            return _.find(roles, { 'Name': systemOwner });
        case 'ViewCallDebugOutput':
            return _.find(roles, { 'Name': systemOwner });
        case 'ViewCallIdInternalCallDebugOutput':
            return (
                (
                    _.find(roles, { 'Name': systemOwner })
                    || _.find(roles, { 'Name': sbcViewer })
                )
                    ? true : false
            );
        case 'SystemUserSummary':
        case 'ViewExceptions':
            return _.find(roles, { 'Name': systemOwner });
        case 'CertificationLevel':
            return _.find(roles, { 'Name': systemOwner });
        case 'ImageExternalLinks':
            return _.find(roles, { 'Name': systemOwner });
        case 'MyCustomerPortal':
            return _.find(roles, { 'Name': accountOwner });
        case 'OwnAccountEdit':
            return _.find(roles, { 'Name': accountOwner });
        case 'AccountApiKeys':
            return _.find(roles, { 'Name': accountOwner });
        case 'AccountAddBranding':
            return _.find(roles, { 'Name': systemOwner });
        case 'AccountAdd':
        case 'AccountEdit':
        case 'AccountPortal':
            return _.find(roles, { 'Name': subContrib });
        case 'ExtendedUI':
            return _.find(roles, { 'Name': systemOwner });
        case 'BillingAsSystem':
            return _.find(roles, { 'Name': systemOwner });
        case 'IsSubSubscriptionFeatures':
            return _.find(roles, { 'Name': systemOwner });
        case 'Billing':
            return (
                (
                    _.find(roles, { 'Name': subContrib })
                    || _.find(roles, { 'Name': subViewer })
                )
                    ? true : false
            );
        case 'Trials':
            return _.find(roles, { 'Name': subViewer })
        case 'Branding':
            return _.find(roles, { 'Name': systemOwner })
        case 'UserPartRegistrationStatus':
            return _.find(roles, { 'Name': systemOwner })
        case 'PlaceOrders':
            var o1 = _.find(roles, { 'Name': accountOwner });
            var o2 = _.find(roles, { 'Name': subContrib });
            return (o1 || o2 ? true : false);
        case 'ServiceAudit' :
            var o1 = _.find(roles, { 'Name': accountOwner });
            var o2 = _.find(roles, { 'Name': systemOwner });
            return (o1 || o2 ? true : false);
        default:
            if(showConsoleLogs()){
                console.log("Unknown UI part to check access for: " + uiPart);
            }
            return false;
    }
}

export function isSystemOwner(roles) {
    return roles
        ? _.find(roles, { 'Name': 'System Owner' })
        : false;
}

export function isSystemAccount(account) {
    return account != null && account.ParentId == null;
}

export const isSyncOnlyContributor = (roles) => {
    if (!roles) {
        return false;
    }
    if (roles != null && roles.length > 0 && roles.length <= 1) {
        return roles[0].Name === Roles.SyncContributor;
    }
    return false;
}

export function canAccessFeature(feature, role, roles, account) {
    return roles
        && _.find(roles, { 'Name': role })
        && account
        && account.hasOwnProperty('Features')
        && account.Features.length > 0
        && _.includes(account.Features, feature);
}

export function isSystemOwnerSystemAcc(baseAccountRoles, account) {
    var isSystemOwnerSystemAcc = baseAccountRoles.find(x => x.Name === "System Owner");
    var isSystemAccount = account.Name === 'system' && account.ParentId == null;
    if (isSystemOwnerSystemAcc && isSystemAccount) {
        return true;
    } else {
        return false;
    }
}

export const isOnlyBrandContributor = (roles = [{ Id: 0, Name: '' }]) => {
    return roles.findIndex(x => x.Name === 'Brand Contributor') >= 0 && roles.length <= 1;
}

// TODO: Depreacte this and use of qunifi-icon-microsoft-teams
export function serviceCodeToIconClass(code) {
    if (code.indexOf('sfb') !== -1) {
        return 'fa-brands fa-skype';
    }
    if (code.indexOf('teams') !== -1) {
        return 'qunifi-icon-microsoft-teams';
    }

    if (code.indexOf('pbx') !== -1) {
        return 'fa-solid fa-phone';
    }

    return '';
}

export function serviceCodeToIcon(code) {
    if (code.indexOf('sfb') !== -1) {
        return <Icon iconName="SkypeForBusinessLogo" />;
    }
    if (code.indexOf('teams') !== -1) {
        return <Icon iconName="TeamsLogoInverse" />;
        // 'qunifi-icon-microsoft-teams';
    }

    if (code.indexOf('pbx') !== -1) {
        return (
            <i className="fa-solid fa-phone"></i>
        );
    }

    return null;
}

export function serviceCodeTypeToIcon(code, includeTextDescription) {
    if (code) {
        switch (code) {
            case 'StandardUser':
                return (
                    <>
                        <Icon tltle="Standard User" iconName="TeamsLogoInverse" style={{ margin: '0 7px' }} />
                        {includeTextDescription ? <span>Standard User</span> : null}
                    </>
                );
                break;
            case 'PhoneAppUser':
                return (
                    <>
                        <i title="Phone App User" className="fa-circle-user fa-light fa-regular" style={{ margin: '0 7px' }}></i>
                        {includeTextDescription ? <span>Phone App User</span> : null}
                    </>
                );
                break;
            case 'AutoAttendant':
                return (
                    <>
                        <Icon title="Auto Attendant" iconName="HeadsetSolid" style={{ margin: '0 7px' }} />
                        {includeTextDescription ? <span>Auto Attendant</span> : null}
                    </>
                );
                break;
            case 'AutoAttendantIncomingOnly':
                return (
                    <>
                        <Icon title="Auto Attendant" iconName="HeadsetSolid" style={{ margin: '0 7px' }} />
                        {includeTextDescription ? <span>Auto Attendant (incoming only)</span> : null}
                    </>
                );
                break;
            case 'Queue':
                return (
                    <>
                        <i title="Queue" className="fa-solid fa-users" style={{ margin: '0 7px' }}></i>
                        {includeTextDescription ? <span>Queue</span> : null}
                    </>
                );
                break;
            case 'QueueIncomingOnly':
                return (
                    <>
                        <i title="Queue" className="fa-solid fa-users" style={{ margin: '0 7px' }}></i>
                        {includeTextDescription ? <span>Queue (incoming only)</span> : null}
                    </>
                );
                break;
            case 'Application':
                return (
                    <>
                        <i className="fa-solid fa-users" style={{ margin: '0 7px' }}></i>
                        {includeTextDescription ? <span>Resource User</span> : null}
                    </>
                );
                break;
            case 'ApplicationIncomingOnly':
                return (
                    <>
                        <Icon iconName="AppIconDefault" style={{ margin: '0 7px' }} />
                        {includeTextDescription ? <span>Application (incoming only)</span> : null}
                    </>
                );
                break;
            case 'NonUC':
                return (
                    <>
                        <i title="Non-UC" className="fa-solid fa-network-wired" style={{ margin: '0 7px' }}></i>
                        {includeTextDescription ? <span>Non-UC Device</span> : null}
                    </>
                );
                break;
        }
    } else {
        return null;
    }
}

export function serviceCodeToIconProps(code) {
    if (code.indexOf('sfb') !== -1) {
        return { iconName: "SkypeForBusinessLogo" };
    }
    if (code.indexOf('teams') !== -1) {
        return { iconName: "TeamsLogoInverse" };
    }
    if (code.indexOf('pbx') !== -1) {
        return { iconName: "Phone" };
    }

    return null;
}

export function iconify(value) {
    if (value && value === true) {
        return <i className="fa-solid fa-check"></i>
    } else {
        return <i className="fa-solid fa-xmark"></i>
    }
}

export function iconWarning() {
    return <i className="fa-solid fa-triangle-exclamation"></i>
}

// Take a call object and expand to include duration, plus neatCallerId and neatCalleeId
export function expandCallData(call) {
    call['CallerId'] = decodeURIComponent(call['CallerId']);
    call['CalleeId'] = decodeURIComponent(call['CalleeId']);

    var rx = /sips?:(.*)@/g;
    var arr1 = rx.exec(call['CallerId']);
    if (arr1 && arr1[1]) {
        call['neatCallerId'] = arr1[1];
    }

    rx = /sips?:(.*)@/g;
    var arr2 = rx.exec(call['CalleeId']);
    if (arr2 && arr2[1]) {
        call['neatCalleeId'] = arr2[1];
    }

    call['Duration'] = null;
    call['DurationMoment'] = null;
    call['DurationSec'] = null;
    if (call['AnsweredOn'] && call['AnsweredOn'] !== call['CompletedOn']) {
        let start = moment.utc(call.AnsweredOn);
        let finish = moment.utc(call.CompletedOn);
        call['DurationMoment'] = moment.duration(finish.diff(start));
        call['Duration'] = call['DurationMoment'].format("hh:mm:ss", { trim: false });
    }

    call['DirectionShort'] = (call.Direction === 'FromPart1' ? 'In' : 'Out');
    call['DirectionLong'] = (call.Direction === 'FromPart1' ? 'Inbound' : 'Outbound');

    return call;
}

//WARNING: Don't remove the below method.
//Dates coming from sip logs need to pass through this, or they are incorrect
const convertSecToMs = (secs = 0) => secs * 1000;
export function expandJsonToCallLog(clData, logEntries, privateLogEntries) {
    clData.DebugOutput = logEntries;
    clData.DebugPrivateOutput = privateLogEntries;

    // We also want to try use the JSON values instead

    let logClData = logEntries.find(d => d.type === 'CALL' && d.hasOwnProperty("content") && d.content.hasOwnProperty("CompletedOn") && d.content.CompletedOn);

    if (logClData) {
        // Preference JSON data, but don't clobber original data with null values
        let newData = _.mergeWith(
            {}, clData, logClData.content,
            (a, b) => b === null ? a : undefined
        );

        if (newData.FromPart1) {
            newData.Direction = 'FromPart1';
        } else {
            newData.Direction = 'FromPart2';
        }

        if (newData.disposition) {
            newData.Disposition = newData.disposition;
        }

        // Convert timestamps to UTC string to emulate original format
        if (newData.AnsweredOn) {
            newData.AnsweredOn = moment.utc(convertSecToMs(newData.AnsweredOn)).format("MMM DD YYYY, H:mm:ss");
        }
        if (newData.CompletedOn) {
            newData.CompletedOn = moment.utc(convertSecToMs(newData.CompletedOn)).format("MMM DD YYYY, H:mm:ss");
        }
        if (newData.StartedOn) {
            newData.StartedOn = moment.utc(convertSecToMs(newData.StartedOn)).format("MMM DD YYYY, H:mm:ss");
        }

        newData = expandCallData(newData);
        return newData;
    }

    return clData;
}

export function generateDemoCallDataUser(resp, respCallback) {
    // For Demo Content we don't have a real user, so generate likely content from the call log
    var temp = 'demo';
    if (resp.callLogData.Direction === 'FromPart1') {
        temp = resp.callLogData.neatCalleeId;
        resp.callLogData.calleeServiceName = 'Teams';
    } else {
        temp = resp.callLogData.neatCallerId;
        resp.callLogData.callerServiceName = 'Teams';
    }

    resp.callLogUser = {
        Part1: {
            Identifier: temp
        },
        Part2: {
            Identifier: 'Demo-Service-User',
        },
    };


    respCallback(resp);
}

export function generateDemoCallDataWrapper(callId, newURL, sipURL, sipPrivateURL) {
    var demoCallLogData = {
        "Id": callId,
        "DebugOutputURL": newURL,

        // Not set in JSON
        "SBCHost": "AEUWA01_DEMO",
        // "DispositionAdvice":"UNUSED test advice",
        // "DiagnosticHold":false,
        // "CallIdPart1CorrelationId":null,
        // "CallIdPart2CorrelationId":"",

        "callerServiceName": "Caller Demo PBX",
        "calleeServiceName": "Callee Demo PBX",
    };

    if (sipURL) {
        demoCallLogData["DebugOutputPublicSIPURL"] = sipURL;
    }
    if (sipPrivateURL) {
        demoCallLogData["DebugOutputPrivateSIPURL"] = sipPrivateURL;
    }

    return {
        // We don't expand this as we'll sort later with JSON file values
        callLogData: demoCallLogData,
        callLogUser: {},
    };
}

export function isBetaFeaturesEnabledOptions(disabled = true) {
    const options = [
        { key: 'SYNCLOGSTOSTORAGE', text: 'New Sync Output Log storage' },
        { key: 'SINGLESTAGEDIALLING', text: 'PhoneApp New Dial Experience' }
    ];

    return options;
}

export function showErrorDebugInfo() {
    const currentDomain = window.location.hostname;
    let allowedDomains = ['admin.uat.call2.team', 'admin.qa.call2.team', 'admin.devtest.call2.team', 'localhost']

    return allowedDomains.includes(currentDomain);
}

export function showConsoleLogs() {
    const currentDomain = window.location.hostname;
    let allowedDomains = ['admin.uat.call2.team', 'admin.qa.call2.team', 'admin.devtest.call2.team', 'localhost']
    return allowedDomains.includes(currentDomain);
}

export class LoadingSplash extends Component {
    render() {
        var text = null;
        if (this.props.text) {
            text = (
                <h4><i className="fa fa-spin fa-solid fa-spinner"></i> {this.props.text}</h4>
            );
        }
        return (
            <div className="splash-screen">
                {text}
                {this.props.children}
            </div>
        );
    }
}

export class ErrorSplash extends Component {
    render() {
        return (
            <div className="splash-screen splash-screen--error">
                {this.props.children}
            </div>
        );
    }
}

const DocumentList = (props) => {

    const documentLink = (d) => {
        // Want d.url + tempToken
        const p = cmsTempAuthRedirect(props.account.Id);
        p.then(function (data) {
            var tempToken = data.UriReadyToken;
            window.location = `${getBaseUrl()}/accounts/${props.account.Id}/documents/${d.Id}?tempToken=${tempToken}`;
        }, function () {
            toast.error("Unable to grab temp token for URL");
        });
    }

    const renderList = () => {
        return props.documents.map((x, id) => {
            return (
                <li key={`${x.URL}_${id}`}>
                    <button
                        className='btn btn-link btn-link--faux'
                        onClick={() => documentLink(x)}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {x.Name}
                    </button>
                </li>
            );
        });
    }

    const renderHeading = () => {
        if (props.heading) {
            return <strong className='document-list-title'>{props.heading}</strong>;
        }
        return null;
    }

    if (!props.documents || props.documents.length === 0) {
        return null;
    }

    return (
        <>
            {renderHeading()}
            <ul className='document-list'>
                {renderList()}
            </ul>
        </>
    );
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
const DocumentListConnected = connect(mapStateToProps, mapDispatchToProps)(DocumentList);
export { DocumentListConnected as DocumentList };

export class EditPasswordField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Default to true if a new part, otherwise false
            canEditPassword: !props.hasPassword
        }
    }

    passwordToggleClick(e) {
        e.preventDefault();
        this.setState(prevState => ({
            canEditPassword: !prevState.canEditPassword,
        }));
    }

    render() {
        var toggleButton = null;
        if (this.props.hasPassword) {
            let passwordToggleText = 'Set new password';
            if (this.state.canEditPassword) {
                passwordToggleText = 'Use existing password';
            }
            toggleButton = (<button className="btn btn-link" onClick={(e) => this.passwordToggleClick(e)}>{passwordToggleText}</button>);
        }

        var passwordPlaceholder = this.state.canEditPassword ? "" : "***********";

        return (
            <>
                <Field placeholder={passwordPlaceholder}
                    disabled={!this.state.canEditPassword}
                    className="form-control"
                    type="password"
                    name={this.props.name}
                    readOnly={this.props.readonly} />
                {toggleButton}
            </>
        );
    }
}

const PBXSIPMapping = ({ values, errors, touched, setFieldValue, reset }) => {
    const [showModal, setShowModal] = useState(false);
    const dropdownOptions = [
        { value: null, label: "No custom mapping" },
        { value: "online", label: "Online" },
        { value: "invisible", label: "Invisible" },
        { value: "away", label: "Away" },
        { value: "busy", label: "Busy" },
        { value: "offline", label: "Offline" },
        { value: "outlolunch", label: "Out to lunch" },
        { value: "inacall", label: "In a call" },
        { value: "berightback", label: "Be right back" }
    ];
    const responseCodeOptions = [
        { value: null, label: "No auto response" },
        { value: "486 User Busy", label: "486 User Busy" },
        { value: "603 Declined", label: "603 Declined" }
    ];
    const pbxMappings = [
        {
            id: "teams_available",
            label: (<>
                <Icon iconName="SkypeCircleCheck" style={onlineStyle} />
                <span> Available </span>
            </>)
        },
        {
            id: "teams_busy",
            label: (<>
                <Icon iconName="StatusCircleOuter" style={doNotDisturbStyle} />
                <span>Busy</span>
            </>)
        },
        {
            id: "teams_inacall",
            label: (<>
                <Icon iconName="StatusCircleOuter" style={doNotDisturbStyle} />
                <span>In a Call</span>
            </>)
        },
        {
            id: "teams_presenting",
            label: (<>
                <Icon iconName="SkypeCircleMinus" style={doNotDisturbStyle} />
                <span>Presenting</span>
            </>)
        },
        {
            id: "teams_donotdisturb",
            label: (<>
                <Icon iconName="SkypeCircleMinus" style={doNotDisturbStyle} />
                <span>Do Not Disturb</span>
            </>)
        },
        {
            id: "teams_berightback",
            label: (<>
                <Icon iconName="AwayStatus" style={awayStyle} />
                <span>Be Right Back</span>
            </>)

        },
        {
            id: "teams_away",
            label: (<>
                <Icon iconName="AwayStatus" style={awayStyle} />
                <span>Away</span>
            </>)
        },
        {
            id: "teams_appearOffline",
            label: (<>
                <Icon iconName="StatusCircleRing" style={invisibleStyle} />
                <Icon iconName="StatusCircleErrorX" style={{ color: '#8a8886', marginLeft: '-19px', paddingRight: '5px' }} />
                <span>Appear Offline</span>
            </>)
        },
        {
            id: "teams_offline",
            label: (<>
                <Icon iconName="StatusCircleRing" style={invisibleStyle} />
                <Icon iconName="StatusCircleErrorX" style={{ color: '#8a8886', marginLeft: '-19px', paddingRight: '5px' }} />
                <span>Offline. When users aren't logged in on any of their devices for a few minutes, they appear offline.</span>
            </>)
        }
    ];
    const presenceDeliveryOptions = [
        { label: 'Select a Value', value: '' },
        { label: 'Disabled', value: 'Disabled' },
        { label: 'Notify', value: 'notify' },
        { label: 'Publish', value: 'publish' },
    ];
    let presenceDeliveryDisabledOption = presenceDeliveryOptions.find(x => x.value === 'Disabled')
    let presenceDeliveryDisabled = values.PresenceDelivery == presenceDeliveryDisabledOption.value;

    return (
        <>
            <button className="btn btn-primary" type="button" disabled={values.IsUIDisabled} onClick={() => setShowModal(true)}>
                Configure Presence
            </button>

            <Modal
                isOpen={showModal}
                onDismiss={() => setShowModal(false)}
                isBlocking={false}
                containerClassName={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    alignItems: 'stretch',
                }}
            >
                <>
                    <div className="table-wrapper presence-status" style={{ padding: '20px', maxWidth: '800px' }}>
                        <h4>Presence and Call Response </h4>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <Toggle
                                        label={(
                                            <div>
                                                <label htmlFor='IsPresenceEnabled'>Presence</label>
                                            </div>
                                        )}
                                        id='IsPresenceEnabled'
                                        defaultChecked={values.IsPresenceEnabled}
                                        onText='On'
                                        offText='Off'
                                        onChange={((_e, checked) => setFieldValue('IsPresenceEnabled', checked))}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <p>Presence updates from Microsoft can be passed on to your PBX.
                            Select a suitable Presence SIP Delivery option for your PBX
                            and configure any state mappings required in the SIP Status column.</p>
                        <p>The SIP Call Response column can be used to have the platform
                            automatically respond to inbound calls when a certain user
                            state is active. For example, you may wish for the platform
                            to send a "603 Decline" for all users that are in the "Busy" state.</p>
                        <hr />
                        {values ?
                            <>
                                <div className='row user-form-parts-wrapper'>
                                    <div className='form-group' style={{ padding: '15px' }}>
                                        <FieldWithDisposition
                                            visibility={'EditOptional'}
                                            formObject={{
                                                Id: "PresenceDelivery",
                                                Label: 'Presence Delivery',
                                                Type: 'select',
                                                Values: presenceDeliveryOptions,
                                                onChangeHook: function (name, newVal, values, setFieldValue) {
                                                    if (newVal === 'Disabled' || newVal == presenceDeliveryDisabledOption) {
                                                        for (let i = 0; i < pbxMappings.length; i++) {
                                                            let fieldName = pbxMappings[i].id;
                                                            let mappingVal = dropdownOptions.find(x => x.value == null);
                                                            setFieldValue(fieldName, mappingVal);
                                                        }
                                                    }
                                                },
                                                isUIDisabled: !values.IsUIDisabled
                                            }}
                                            formValues={values}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <table>
                                    <tbody>
                                        <tr>
                                            <th><div style={{ padding: '0 20px', marginBottom: '20px' }}>Teams Status</div></th>
                                            <th><div style={{ padding: '0 20px', marginBottom: '20px' }}>SIP Status</div></th>
                                            <th><div style={{ padding: '0 20px', marginBottom: '20px' }}>Call Response</div></th>
                                        </tr>
                                        {pbxMappings.map((obj, i) => {
                                            let fieldType = obj.id + '_response';
                                            let mappingVal = values[obj.id] && values[obj.id] != null && values[obj.id] != 'null' ? values[obj.id] : dropdownOptions.find(x => x.value == null);
                                            if (typeof (values[obj.id]) == 'string') {
                                                if (values[obj.id].includes('{')) {
                                                    mappingVal = JSON.parse(values[obj.id]);
                                                } else {
                                                    mappingVal = dropdownOptions.find(x => x.value == values[obj.id])
                                                }
                                            }
                                            let responseVal = values[fieldType] && values[fieldType] != null && values[fieldType] != 'null' ? values[fieldType] : responseCodeOptions.find(x => x.value == null);
                                            if (typeof (values[fieldType]) == 'string') {
                                                if (values[fieldType].includes('{')) {
                                                    responseVal = JSON.parse(values[fieldType]);
                                                } else {
                                                    let responseCode = responseCodeOptions.find(x => x.value == values[fieldType]);
                                                    responseVal = responseCode ? responseCode : { value: values[fieldType], label: values[fieldType] };
                                                }
                                            }

                                            return (
                                                <tr key={i}>
                                                    <td style={tableColumnStyle}>
                                                        {obj.label}
                                                    </td>
                                                    <td style={tableColumnStyle}>
                                                        <CreatableSingle
                                                            value={mappingVal}
                                                            options={dropdownOptions}
                                                            name={obj.id}
                                                            setFieldValue={setFieldValue}
                                                            isDisabled={presenceDeliveryDisabled || !values.IsUIDisabled}
                                                        />
                                                    </td>
                                                    <td style={tableColumnBStyle}>
                                                        <CreatableSingle
                                                            value={responseVal}
                                                            options={responseCodeOptions}
                                                            name={fieldType}
                                                            setFieldValue={setFieldValue}
                                                            isDisabled={!values.IsUIDisabled}
                                                        />
                                                        <span className="error-message"> {errors[fieldType] && errors[fieldType]}</span>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </table>

                            </>
                            : null}
                        <DialogFooter>
                            <button type="button" className="btn btn-secondary" disabled={!values.IsUIDisabled || !showModal} onClick={() => { setShowModal(false); reset(); }} style={{ marginLeft: '10px' }}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" disabled={!values.IsUIDisabled || !showModal} onClick={() => setShowModal(false)} style={{ marginLeft: '10px' }}>
                                Ok
                            </button>
                        </DialogFooter>
                    </div>
                </>
            </Modal>
        </>
    );
}
export { PBXSIPMapping };
const tableColumnStyle = {
    width: '33%',
    wordBreak: 'break-word',
    padding: '5px 20px'
};
const tableColumnBStyle = {
    width: '33%',
    wordBreak: 'break-word',
    padding: '5px 20px'
};
const awayStyle = {
    color: '#ffaa44',
    paddingRight: '5px'
}
const doNotDisturbStyle = {
    color: '#c50f1f',
    paddingRight: '5px'
}
const onlineStyle = {
    color: '#6bb700',
    paddingRight: '5px'
}
const invisibleStyle = {
    color: '#8a8886',
    paddingRight: '5px'
}
export default class CreatableSingle extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (newValue) => {
        this.props.setFieldValue && this.props.setFieldValue(this.props.name, newValue);
    };
    render() {
        return (
            <CreatableSelect
                value={this.props.value}
                isClearable
                placeholder="Select or Enter Custom Option..."
                onChange={this.handleChange}
                options={this.props.options}
                isDisabled={this.props.isDisabled}
            />
        );
    }
}

export const isJson = (jsonString = '') => {
    try {
        JSON.parse(jsonString);
    } catch {
        return false;
    }
    return true;
}

export function isValidWord(word) {
    const restrictedCharacters = XRegExp("^[\\p{L}\\p{M}\\p{P}\\p{N}\\|\\+\\s]+$");
    return restrictedCharacters.test(word)
}

export function generateServicePageURL(accountId, service) {
    if (!service || !service.Id || !service.ServiceCode || !service.ServiceVariantCode) {
        return null;
    }

    var serviceName = 'pbx';
    if (service.ServiceCode !== 'pbx') {
        serviceName = 'teams';
    } else if (service.ServiceVariantCode.includes('TRUNK')) {
        serviceName = 'trunk';
    }

    return generatePath("/portal/:accountId/services/:serviceName/:accountServiceId", {
        accountId: accountId,
        serviceName: serviceName,
        accountServiceId: service.Id
    });
}
export const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length == Object.keys(obj2).length &&
    Object.keys(obj1).every(key =>
        obj2.hasOwnProperty(key) && obj1[key] == obj2[key]
    );
export const compareOBs = (obj1, obj2, key) => {
    if (Array.isArray(obj1)) {
        return obj1.length === obj2.length
            && obj1.every((item) => {
                let match = key ? obj2.find(x => x[key] === item[key]) : obj2.find(x => x === item);
                if (match && typeof match == 'object') {
                    if (!shallowCompare(item, match)) {
                        return false;
                    }
                } else if (typeof match == 'string' && match != item) {
                    if (match != item) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (!match) {
                    return false;
                }
                return true;
            });
    } else {
        return shallowCompare(obj1, obj2);
    }
}

export function generateCallSipDebugLink(baseDebugUrl, callId, callStartedOn) {
    return generateSipDebugLink(baseDebugUrl, true, callId, callStartedOn);
}
export function generateRegisterSipDebugLink(baseDebugUrl, callId, callStartedOn) {
    return generateSipDebugLink(baseDebugUrl, false, callId, callStartedOn);
}
export function generateSipDebugLink(baseDebugUrl, isCall, callId, callStartedOn) {
    let s1 = moment.utc(callStartedOn);
    let offset_s = 120;
    let halfday_s = 12 * 60 * 60;
    let searchKey = isCall ? '1_call' : '1_registration';
    // Make the trace search, basically to/from time and call-id,
    // plus some default values to clear search
    let search = {
        "timestamp": {
            "from": (s1.unix() - offset_s) * 1000,
            "to": (s1.unix() + halfday_s) * 1000,
        },
        "param": {
            "search": {},
            "location": {},
            "transaction": {
                "call": isCall,
                "registration": !isCall,
                "rest": false
            },
            "id": {},
            "timezone": {
                "value": 0,
                "name": "UTC"
            }
        }
    };

    // Different key depending on call vs registration
    search['param']['search'][searchKey] = {
        "id": 1,
        "callid": [
            callId
        ],
        "uuid": []
    };

    let str = JSON.stringify(search);
    // Based on onShareLink() in HOMER
    return baseDebugUrl + '?' + encodeURIComponent(str) + '=';
}
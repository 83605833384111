import React, { Component } from 'react';
import ActionHeader from '../ActionHeader.js';
import { withRouter } from 'react-router-dom';
import { cmsGetPBXStatus, cmsGetTrunkStatus, cmsGetSingleServiceUserPartsPromise, cmsGetFullService } from '../CallMSAPI.js';
import { expandRegistration } from './RegistrationLight.js';
import { connect } from 'react-redux'; 
import { generateRegisterSipDebugLink, isSystemOwner } from '../CallMSUIHelpers.js';

var moment = require('moment-timezone');
var _ = require('lodash');

const pendingStatus = {
    height: '10px',
    width: '10px',
    marginLeft:'3px',
    backgroundColor: 'orange',
    borderRadius: '50%',
    display: 'inline-block'
};
const failedStatus = {
    height: '10px',
    width: '10px',
    marginLeft: '3px',
    backgroundColor: 'red',
    borderRadius: '50%',
    display: 'inline-block'
};
const successStatus = {
    height: '10px',
    width: '10px',
    marginLeft: '3px',
    backgroundColor: 'green',
    borderRadius: '50%',
    display: 'inline-block'
};

const registrationStatusProps = [
    "RegistrationStatus",
    "ContactURI",
    "RegistrarURI",
    "UserAgent",
    "RegistrationExpiry",
    "RegistrationMessage",
    "RegistrationTime",
    "LastSuccessTime",
    "CurrentSBC",
    "PreviousSBC",
    "PreviousSBCChangeTime",
    "ServiceUserPartId",
    "CallId"
];
const registrationStatusFailureProps = [
    "LastFailureTime",
    "LastFailureMessage",
];
const generateDebugLink = (account, regStartedOn, callId) => {
    if (account && account.Links && regStartedOn && callId) {
        let baseDebugUrl = _.find(account.Links, { "ExternalLinkTypeName": "sip-debug" });
        if (baseDebugUrl) {
            baseDebugUrl = baseDebugUrl.URL;
            let url1 = generateRegisterSipDebugLink(baseDebugUrl, callId, regStartedOn);
            return (
                <a href={url1} target="_blank">
                    {callId} (View Trace)
                </a>
            );
        }
    }
    return callId;
}


class ServiceUserRegStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            userPart: null,
            accountService: null,
            sipUsername: null,
            loading: true
        }
    }

    componentDidMount() {
        var self = this;
        var params = self.props.match.params;
        if (params.accountId && params.serviceName && params.serviceUserPartId) {
            cmsGetPBXStatus(params.accountId, params.serviceName, params.serviceUserPartId).then(function (res) {
                if (res) {
                    var data = res.data;
                    self.setState({ data: data });

                    return cmsGetSingleServiceUserPartsPromise(params.accountId, params.serviceName, params.serviceUserPartId)
                }
            }).then(function (res) {
                if (res && res.data) {
                    self.setState({ userPart: res.data });
                    return cmsGetFullService(params.accountId, params.serviceName)
                }
            }).then(function (res) {
                if (res && res.data) {
                    if (self.state.userPart
                        && self.state.userPart.Identifier
                        && res.data.PBXSettings
                        && res.data.PBXSettings.Realm
                    ) {
                        var realm = res.data.PBXSettings.Realm;
                        if (!realm.includes('@')) {
                            realm = '@' + realm;
                        }
                        var sipuser = <>{self.state.userPart.Identifier}<em>{realm}</em></>;
                    }
                    self.setState({ accountService: res.data, sipUsername: sipuser, loading: false });
                }
            })
        } else if (params.accountId && params.serviceName && !params.serviceUserPartId) {
            cmsGetTrunkStatus(params.accountId, params.serviceName).then(function (res) {
                if (res) {
                    var data = res.data;
                    self.setState({ data: data });
                    return cmsGetFullService(params.accountId, params.serviceName)
                }
            }).then(function (res) {
                if (res && res.data) {
                    if (res.data.TrunkSettings
                        && res.data.TrunkSettings.Identifier
                        && res.data.PBXSettings
                        && res.data.PBXSettings.Realm
                    ) {
                        var realm = res.data.PBXSettings.Realm;
                        if (!realm.includes('@')) {
                            realm = '@' + realm;
                        }
                        var sipuser = <>{res.data.TrunkSettings.Identifier}<em>{realm}</em></>;
                    }
                    self.setState({ accountService: res.data, sipUsername: sipuser, loading: false });
                }
            })
        }
    }

    renderRowSyncEnabled = (key = '', headerText = '', dataCellText = '', sysOwner = false) => {
        const { accountService } = this.state;

        const row = (key = '', headerText, dataCellText, sysOwner = false) => (
            <tr className={sysOwner ? 'system-owner-bg' : ''} key={key}>
                <th className='w-50'>
                    {headerText}
                </th>
                <td className='w-50'>{dataCellText}</td>
            </tr>
        );

        if (!accountService.SyncEnabled) {

            return row(key, headerText, dataCellText);

        } else if (accountService.SyncEnabled && sysOwner) {

            return row(key,
                <>
                    {headerText}
                    <i className='fa-solid fa-user-secret m-2' title='System Owner Only' />
                </>, dataCellText, sysOwner);

        } else if (accountService.SyncEnabled && !sysOwner) {
            return null;
        }
    }

    render() {
        var self = this;
        var data = self.state.data;
        const sysOwner = isSystemOwner(self.props.baseAccount.roles);

        const { loading } = this.state;

        var headerText = "Registration Status"; 

        var tableBody = [];
        if (!loading) {
            if (self.state.sipUsername) {
                tableBody.push(this.renderRowSyncEnabled('accServiceName', 'SIP Username/Realm:', self.state.sipUsername, sysOwner));
                
            }

            if (self.state.accountService) {
                tableBody.push(
                    <tr key="accServiceName1">
                        <th style={{ width: '50%' }}>Service Name: </th>
                        <td style={{ width: '50%' }}>{self.state.accountService.Name}</td>
                    </tr>
                );
            }

            registrationStatusProps.forEach(function (itemKey) {
                var keyString = formatPropName(itemKey);
                var valueString = data[itemKey];
                var extraCssClass = '';
                if (itemKey.indexOf('URI') > -1) {
                    extraCssClass += ' allow-uri-break';
                }

                if (itemKey === 'PreviousSBC') {
                    if (data['PreviousSBCChangeTime'] && data['PreviousSBC']) {
                        var now = moment.tz();
                        var regTime = moment.tz(data['PreviousSBCChangeTime'], "UTC");
                        var regDateString = regTime.fromNow();
                        var difference = moment(now).diff(regTime, 'days') >= 7;
                        if (itemKey === 'PreviousSBC' && difference >= 7) {

                            if (sysOwner) {
                                valueString = (
                                    <div className="system-owner-action">
                                        <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ margin: '0 10px', fontSize: '25px' }}></i>
                                        <span>{data[itemKey]} <small>({data['PreviousSBCChangeTime']})</small></span>
                                    </div>
                                );
                            } else {
                                return (null);
                            }
                        }
                    } else {
                        return (null);
                    }
                } else if (itemKey === 'RegistrationExpiry'
                    || itemKey === 'RegistrationTime'
                    || itemKey === 'LastSuccessTime'
                    || itemKey === 'PreviousSBCChangeTime') {
                    //only show LastSuccessTime if it is different than RegistrationTime
                    if (itemKey === 'LastSuccessTime' && data[itemKey] === data['RegistrationTime']) {
                        return (null)
                    } else if ((itemKey === 'PreviousSBC' && !data['PreviousSBC']) //only show these fields if set
                        || (itemKey === 'PreviousSBCChangeTime' && !data['PreviousSBCChangeTime'])) {
                        return (null)
                    }
                    //format dates
                    var dateString = moment.tz(data[itemKey], "UTC");
                    valueString = data[itemKey];
                    if (dateString.isValid()) {
                        valueString = dateString.fromNow() + " (" + data[itemKey] + ")";
                    }
                } else if (itemKey === 'RegistrationMessage'
                    && data['RegistrationMessage']) {
                    //expand registration message if possible
                    valueString = expandRegistration(data['RegistrationMessage']);
                } else if (itemKey === 'RegistrationStatus') {
                    var status = data[itemKey].toLowerCase();
                    if (status.includes('failed')) {
                        valueString = (
                            <>
                                {data[itemKey]}
                                <span style={failedStatus} />
                            </>
                        );
                    } else if (status.includes('pending')) {
                        valueString = (
                            <>
                                {data[itemKey]}
                                <span style={pendingStatus} />
                            </>
                        );
                    } else if (status.includes('success')) {
                        valueString = (
                            <>
                                {data[itemKey]}
                                <span style={successStatus} />
                            </>
                        );
                    }
                }
                switch (itemKey) {
                    case 'ContactURI':
                        tableBody.push(self.renderRowSyncEnabled(itemKey, `${keyString}:`,
                            <div
                                className={extraCssClass}>
                                {valueString}
                            </div>, sysOwner));
                        break;
                    case 'CallId':
                        tableBody.push(
                            <tr key={itemKey}>
                                <th style={{ width: '50%' }}>{keyString}:</th>
                                <td className={extraCssClass} style={{ width: '50%' }}>
                                    {generateDebugLink(self.props.baseAccount, data['RegistrationTime'], valueString)}
                                </td>
                            </tr>
                        );
                        break;
                    default:
                        if (itemKey === 'PreviousSBC' && !data[itemKey]) {
                            return (null);

                        } else {
                            tableBody.push(
                                <tr key={itemKey}>
                                    <th style={{ width: '50%' }}>{keyString}:</th>
                                    <td className={extraCssClass} style={{ width: '50%' }}>{valueString}</td>
                                </tr>
                            )
                        }
                        break;
                }

            })
        } else {
            tableBody.push(<tr key='loading'><th>Loading...</th></tr>);
        }

        var errorTableBody = [];
        if (self.state.data) {
            registrationStatusFailureProps.forEach(function (itemKey) {
                /**
                 * in a separate table, if LastFailureMessage or LastFailureTime exist, and are different to 
                 * RegistrationMessage and RegistrationTime, then format/expand this data and show here
                 */
                if (data[itemKey]
                    && (
                        (itemKey === 'LastFailureMessage'
                            && data['RegistrationMessage']
                            && data['LastFailureMessage'] != data['RegistrationMessage'])
                        || (itemKey === 'LastFailureMessage' && data['LastFailureMessage'] && !data['RegistrationMessage'])
                        || (itemKey === 'LastFailureTime' && data['LastFailureTime'] && !data['RegistrationTime'])
                        || (itemKey === 'LastFailureTime'
                            && data['RegistrationTime']
                            && data['RegistrationTime'] != data['LastFailureTime'])
                    )) {
                    var keyString = formatPropName(itemKey);
                    var valueString = data[itemKey];
                    if (itemKey === 'LastFailureTime') {
                        var dateString = moment.tz(data[itemKey], "UTC");
                        valueString = data[itemKey];
                        if (dateString.isValid()) {
                            valueString = dateString.fromNow() + " (" + data[itemKey] + ")";
                        }
                    } else if (itemKey === 'LastFailureMessage') {
                        var now = moment.tz();
                        var failTime = moment.tz(data['LastFailureTime'], "UTC");
                        var difference = moment(now).diff(failTime, 'days') >= 7;

                        if (difference >= 7) {
                            if (sysOwner) {
                                valueString = (
                                    <div className="system-owner-action">
                                        <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ margin: '0 10px', fontSize: '25px' }}></i>
                                        <span>{expandRegistration(data['LastFailureMessage'])}</span>
                                    </div>
                                );
                            } else {
                                return (null);
                            }
                        }
                    }
                    errorTableBody.push(
                        <tr key={itemKey}>
                            <th style={{ width: '50%' }}>{keyString}:</th>
                            <td style={{ width: '50%', wordBreak: 'break-all' }}>{valueString}</td>
                        </tr>
                    )
                } else {
                    return (null)
                }
            })
        }


        return (
            <>
                <div >
                    <ActionHeader smallHeader={true} headerText={headerText} >
                    </ActionHeader>
                </div>

                <br />

                <table className="table">
                    <tbody>
                        {tableBody}
                    </tbody>
                </table>
                <br />
                <br />
                <table className="table">
                    <tbody>
                        {errorTableBody}
                    </tbody>
                </table>
            </>
        );
    }
}
export default connect(state => ({
    baseAccount: state.account.baseAccount,
    account: state.account.account,
    services: state.services.rawList
}))(withRouter(ServiceUserRegStatus));

export function formatPropName(itemKey) {
    switch (itemKey) {
        case "ServiceUserPartId":
            return "Service User Part Id";
            break;
        case "RegistrationStatus":
            return "Registration Status"
            break;
        case "ContactURI":
            return "Contact URI"
            break;
        case "RegistrarURI":
            return "Registrar URI"
            break;
        case "UserAgent":
            return "User Agent"
            break;
        case "RegistrationExpiry":
            return "Registration Expiry"
            break;
        case "RegistrationMessage":
            return "Registration Message"
            break;
        case "RegistrationTime":
            return "Registration Time"
            break;
        case "LastSuccessTime":
            return "Last Success Time"
            break;
        case "LastFailureTime":
            return "Last Failure Time"
            break;
        case "LastFailureMessage":
            return "Last Failure Message"
            break;
        case "CurrentSBC":
            return "Current SBC"
            break;
        case "PreviousSBC":
            return "Previous SBC"
            break;
        case "PreviousSBCChangeTime":
            return "Previous SBC Change Time"
        case "CallId":
            return "Register Call ID"
            break;
    }
}

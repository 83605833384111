import React, { useState } from "react";
import { TeachingBubble, Toggle, DefaultButton, Dialog, DialogType, PrimaryButton } from "@fluentui/react";
import { getConsentForAutoSync } from "../../MSALAuthProvider.js";
import { cmsEnableAutoSync, cmsDisableAutoSync, cmsGetSyncModuleDefaultSettings, cmsStartOAuthServiceSync, cmsRecordConsentGranted } from "../../CallMSAPI.js";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index.js';

var _ = require('lodash');

const AutoSyncRow = (props) => {
    const [showZeroTouchHelp, setShowZeroTouchHelp] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showConsentModal, setShowConsentModal] = useState(false);
    const [showSyncModal, setShowSyncModal] = useState(false);

    const toggleZeroTouchHelp = () => {
        setShowZeroTouchHelp(!showZeroTouchHelp);
    };

    const dismissZeroTouchHelp = () => {
        setShowZeroTouchHelp(false);
    };

    const dismissConfirmDialog = (newVal) => {
        setShowConfirmModal(false);
        props.setFieldValue("ZeroTouchProvisioning", newVal);
    }
    const showConfirmDialog = () => {
        setShowConfirmModal(true)
    }
    const dismissConsentDialog = (newVal) => {
        setShowConsentModal(false);
        props.setFieldValue("ZeroTouchProvisioning", newVal);
    }
    const showConsentDialog = (newVal) => {
        setShowConsentModal(true);
    }
    const showSyncDialog = () => {
        setShowSyncModal(true);
    }
    const dismissSyncDialog = () => {
        setShowSyncModal(false);
    }

    const modalProps = {
        isBlocking: true,
        className: 'allow-overflow-modal'
    };


    return (
        <>
            <div className="col-md-4">
                {props.values.NextGenSyncToggle ? (
                    <Toggle
                        label={
                            <div>
                                <label htmlFor="zeroTouchBtn">Auto Sync</label>
                                <button
                                    id="zeroTouchBtn"
                                    className="btn btn-link btn--faux-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleZeroTouchHelp();
                                    }}
                                >
                                    <i
                                        className="fa-solid fa-question-circle"
                                        id={"zeroTouchHelp"}
                                    />
                                </button>
                                {showZeroTouchHelp && (
                                    <TeachingBubble
                                        target={"#zeroTouchHelp"}
                                        hasCondensedHeadline={true}
                                        onDismiss={dismissZeroTouchHelp}
                                        hasCloseIcon={true}
                                        closeButtonAriaLabel="Close"
                                    >
                                        <>
                                            <p>
                                                Enabling Auto Sync will allow user changes 
                                                to process without the need of manual authentication 
                                                of the Teams Tenant. 
                                            </p>
                                            <p>
                                                You may still be required to click the sync button 
                                                to import new users or run PBX syncs. 
                                            </p>
                                        </>
                                    </TeachingBubble>
                                )}
                            </div>
                        }
                        defaultChecked={props.values.ZeroTouchProvisioning}
                        checked={props.values.ZeroTouchProvisioning}
                        onText="On"
                        offText="Off"
                        onChange={(_e, checked) => {
                            if (!checked) {
                                return showConfirmDialog();
                            } else {
                                return showConsentDialog();
                            }

                        }}
                        disabled={props.disabled}
                    />
                ) : null}
                <Dialog
                    hidden={!showConfirmModal}
                    onDismiss={() => dismissConfirmDialog(false)}
                    modalProps={modalProps}
                    style={{ minWidth: '80%', overflow: 'visible' }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Confirmation Required',
                        subText: 'This action will take effect immediately. You will have to manually synchronize changes.'
                    }}
                >
                    <div>
                        <PrimaryButton onClick={() => {
                            let tenantId = _.find(props.fullTeams[0].SyncSettings, { Key: 'TenantId' });
                            let tenantIdVal = tenantId ? tenantId.Value : null;
                            cmsDisableAutoSync(props.account.Id, props.fullTeams[0].Id, tenantIdVal).then(res => {
                                props.refetchServices();
                                dismissConfirmDialog(false)
                            }).catch(err => {
                                dismissConfirmDialog(false)
                                toast.error(err.message);
                            })
                        }} style={{ marginRight: '4px' }}>Confirm</PrimaryButton>
                        <DefaultButton onClick={() => dismissConfirmDialog(true)} style={{ marginLeft: '4px' }}>Cancel</DefaultButton>
                    </div>
                </Dialog>
                <Dialog
                    hidden={!showConsentModal}
                    onDismiss={() => dismissConsentDialog(false)}
                    modalProps={modalProps}
                    style={{ minWidth: '80%', overflow: 'visible' }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Microsoft Authentication Required',
                        subText: 'You must grant consent on behalf of your organization in order to allow the service to automatically sync with your Teams tenant.'
                    }}
                >
                    <div>
                        <PrimaryButton onClick={() => {
                            getConsentForAutoSync(props.account.Id).then(res => {
                                if (res) {
                                    cmsRecordConsentGranted(props.account.Id, props.fullTeams[0].Id, 'AutoSync');
                                    cmsEnableAutoSync(props.account.Id, props.fullTeams[0].Id, res).then(res => {
                                        props.refetchServices();
                                        setShowSyncModal(true);
                                    }).catch(err => {
                                        setShowSyncModal(false);
                                        toast.error(err.message);
                                    })
                                }
                                dismissConsentDialog(true)
                            });
                        }} style={{ marginRight: '4px' }}>Proceed</PrimaryButton>
                        <DefaultButton onClick={() => dismissConsentDialog(false)} style={{ marginLeft: '4px' }}>Cancel</DefaultButton>
                    </div>
                </Dialog>
                <Dialog
                    hidden={!showSyncModal}
                    onDismiss={() => dismissSyncDialog()}
                    modalProps={modalProps}
                    style={{ minWidth: '80%', overflow: 'visible' }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Manual Sync Required',
                        className: 'manualSync',
                        subText: 'To complete activation a one-time manual sync needs to be run with your Microsoft Administrator Credentials.'
                    }}
                >
                    <div>
                        <PrimaryButton onClick={() => {
                            dismissSyncDialog();
                            cmsStartOAuthServiceSync(
                                props.account.Id,
                                props.fullTeams[0]
                            );
                        }}><i className="fa fa-warning" style={{ marginRight: '10px' }}></i>Sync Now - Changes Queued</PrimaryButton>

                    </div>
                </Dialog>
            </div>
        </>
    );
};
const mapStateToProps = state => {
    const account = state.account;
    const services = state.services;
    return {
        account: account.account,
        fullTeams: services.fullTeams
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AutoSyncRow);

import React, { Component } from 'react';
import { getErrMsg, cmsDeleteAdminInvitePromise, cmsGet } from '../CallMSAPI.js';
import ExpandingReactTable from '../ExpandingReactTable';
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
var moment = require('moment-timezone');

class AdminInviteReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1
        }

        this.updateData = this.updateData.bind(this);
        this.tableRef = React.createRef();
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            if(showConsoleLogs()) {
                console.log("Starting an update");
            }
            self.tableRef.current.forceTableUpdate();
        }
    }

    updateData(state) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'admininvitations',
            relevantOnly: true
        };

        // Bail if we can't make the API call...
        if (apiParams.accountId === undefined) {
            return;
        }

        // Handle sorting/order
        //apiParams['SortBy'] = 'FirstName';
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'name') {
                apiParams['SortBy'] = 'FirstName';
            } else if (state.sorted[0].id === 'email') {
                apiParams['SortBy'] = 'Email';
            } else if (state.sorted[0].id === 'sentdate') {
                apiParams['SortBy'] = 'SentDate';
            }
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Our version of normal search text...
                if (f.id === 'name' || f.id === 'email') {
                    apiParams['searchText'] = f.value;
                    return;
                }
            });
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGet(
            apiParams,
            function (callmsData) {
                self.setState({
                    data: callmsData.Results,
                    pageCount: callmsData.PageCount,
                    loading: false
                });
                self.props.loadCompleteCallback && self.props.loadCompleteCallback();
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    deleteUserInvite(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this invitation?")) {
            cmsDeleteAdminInvitePromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("Invitation deleted");
            }, function (err) {
                toast.error("Unable to delete invitation : " + getErrMsg(err));
            }).finally(function () {
               self.tableRef.current.forceTableUpdate();
            })
        }
    }

    render() {
        var self = this;
        const data = this.state.data;
        var cols = [
            {
                id: "name",
                Header: "Name",
                accessor: function (d) {
                    if (!d.FirstName && !d.LastName) {
                        return <em>Not Set</em>;
                    }
                    return (d.FirstName ? d.FirstName : '') + ' ' + (d.LastName ? d.LastName : '');
                }
            },
            {
                id: "email",
                Header: "Email",
                accessor: d => d.Email
            },
            {
                id: "sentdate",
                Header: "Sent Date",
                accessor: function (d) {
                    let t = moment.tz(d.SentDate, "UTC");
                    if (t.isValid()) {
                        return t.fromNow();
                    }
                },
                filterable: false
            },
            {
                id: "status",
                Header: "Status",
                accessor: function (d) {
                    if (d.Status === 'Active') {
                        return 'Not Accepted';
                    } else {
                        return d.Status;
                    }
                },
                filterable: false,
                sortable: false
            },
            {
                id: "delete",
                Header: "",
                className: "text-right",
                width: 50,
                accessor: function (d) {
                    return (
                        <button onClick={(e) => self.deleteUserInvite(e, d)} className="btn btn-xs btn-default">
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    );
                },
                filterable: false
            }
        ];

        return (
            <ExpandingReactTable
                data={data}
                pages={this.state.pageCount}
                resizable={false}
                updateData={self.updateData}
                filterable
                columns={cols}
                defaultSorted={[{
                    id: 'sentdate',
                    desc: true,
                }]}
                minRows={0}
                showPageJump={false}
                multiSort={false}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"You don't have any pending admin invitations."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                ref={this.tableRef}
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminInviteReactTable);

import React, { Component } from 'react';
import { cmsGetTrunkStatus, cmsGetPBXStatus } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { canAccess } from '../CallMSUIHelpers';
import { getErrorHelpSingleMessage } from '../components/SIPErrors';

var _ = require('lodash');
var moment = require('moment-timezone');

export class RegistrationLight extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updating:false,
            updateTime: 0,
            data: null,
            isHovering: false
        }
        this.updateRegistrationStatusOnHover = this.updateRegistrationStatusOnHover.bind(this);
    }

    componentDidMount() {
        //If RegistrationStatus is null then service is a trunk, call the next function
        if (!this.props.RegistrationStatus) {
            this.updateRegistrationStatusOnHover();
        }
    }

    updateRegistrationStatusOnHover() {
        var self = this;
        if (this.state.updating === false) {
            this.setState(
                { updating: true },
                function () {
                    self.props.updateCb && self.props.updateCb(
                        function (data) {
                            // Expand raw REGISTER response to a more friendly message for our end user
                            data.RegistrationMessageExpanded = data.RegistrationMessage ? expandRegistration(data.RegistrationMessage) : '';
                            self.setState({ data: data, updateTime: new Date().getTime(), updating: false});
                        }, function (err) {
                            self.setState({ updating: false});
                            toast.error(err);
                        }
                    )
                }
            );
        }
    }

    /**
     * IsHovering is used to trigger another PBX update.
     * 
     * @param {any} prevProps
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isHovering === false && this.state.isHovering === true) {
            this.updateRegistrationStatusOnHover();
        }
    }

    render() {
        let self = this;
        /**
         * Qtooltip just does the popup...
         */
        const Qtooltip = props => {
            let base = '';
            let pathName = '';
            let clickable = false;

            const isInteractive = (cb = () => { }) => {
                return clickable ? cb() : false;
            }

            var iconState = 'circle-unused';
            var iconClass = 'fa-circle';
            if (props.iconState === 'ok') {
                iconState = 'circle-ok';
            } else if (props.iconState === 'ok-stale') {
                iconState = 'circle-warn';
                iconClass = 'fa-circle';
            } else if (props.iconState === 'na') {
                iconState = 'circle-na';
            } else if (props.iconState === 'warn') {
                iconState = 'circle-warn';
            } else if (props.iconState === 'problem') {
                iconState = 'circle-problem';
            }

            var messages = [];
            if (props.messages !== undefined) {
                messages = props.messages;
            }

            if (props.last !== undefined) {
                let t = moment.tz(props.last, "UTC");
                if (t.isValid()) {
                    messages.push('Last: ' + t.fromNow());
                }
            }

            if (props.next !== undefined) {
                let t = moment.tz(props.next, "UTC");
                if (t.isValid()) {
                    if (t.isAfter()) {
                        messages.push('Next: ' + t.fromNow());
                    } else{
                        messages.push('Expired: ' + t.fromNow());
                    }
                }
            }

            messages =_.map(messages, function (m) {
                return (<li key={m}>{m}</li>);
            });

            if (messages.length === 0) {
                messages.push(
                    <li key={-1}>Loading...</li>
                );
            } else if (messages.length > 0
                && this.props.canAccessUserPartRegistrationStatus
                && this.props.ServiceDoRegistration
            ) {
                base = "/portal/" + self.props.accountId;
                pathName = "/services/" + self.props.service.Id + "/status";
                clickable = true;

                // If props.User the service is a PBX and reg is _per user_, alter URL accordingly
                if (self.props.serviceUserPartId) {
                    pathName = "/users/" + self.props.serviceUserPartId + "/services/" + self.props.service.Id + "/status";
                }

                messages.push(
                    <li key={-2}>
                        <Link className="ext_link" to={base + pathName} target="_blank" style={{ padding: '0 5px' }}>
                           Click for more details
                       </Link>
                    </li>
                );
            }

            messages = (<ul className="preview-reg-messages">{messages}</ul>);

            return (
                <div className="q-popup-status-wrapper text-center" 
                onMouseEnter={function () {
                    setTimeout(function () {
                        if (!self.state.isHovering) {
                            self.setState({ isHovering: true })
                        }
                    }, 100)
                }}
                onMouseLeave={function () {
                    setTimeout(function () {
                        if (self.state.isHovering) {
                            self.setState({ isHovering: false })
                        }
                    }, 100)
                }}
                onClick={() => {
                    isInteractive(() => {
                        window.open(base + pathName);
                    });
                }}>
                    <i
                        role='button'
                        className={`${iconState} fa ${iconClass}`}
                        data-original-title="Registration Status"
                    />

                    <div className="q-tooltip row-hover-only">
                        <h5>Registration Status</h5>
                        {messages}
                    </div>
                </div>
            );
        };

        var status = this.props.RegistrationStatus;
        if (this.state.data && this.state.data.RegistrationStatus) {
            if (!this.props.updateTime || this.props.updateTime < this.state.data.updateTime) {
                status = this.state.data.RegistrationStatus;
            }
        }

        if (!status || status === 'Disabled') {
            let serviceType = self.props.service && self.props.service.ServiceVariantCode === 'TRUNK' ? 'TRUNK' : 'PBX';
            return (
                <Qtooltip messages={[`${serviceType} registration not enabled.`]} iconState="na" />
            );
        }

        if (!this.props.ServiceDoRegistration) {
            return (
                <Qtooltip messages={["Disabled."]} />
            );
        }

        // Success, Failed, Pending, Disabled
        var iconState = 'problem';
        var extraProps = {};
        if (status === 'Success') {
            iconState = 'ok';
        } else if (status === 'Expired') {
            extraProps.messages = ['This suggests your PBX is not responding'
                + ' fast enough to registration attempts. Ensure the platform is'
                + ' allowed through any SIP throttling or firewall devices. '
            ];
            iconState = 'ok-stale';
        } else if (status === 'Pending') {
            extraProps.messages = ['First registration queued'];
            iconState = 'warn';
        }

        if (this.state.data) {
            if (this.state.data.RegistrationMessage) {
                extraProps.messages = [this.state.data.RegistrationMessageExpanded];
            }
            if (this.state.data.RegistrationExpiry) {
                extraProps.next = this.state.data.RegistrationExpiry;
            }
            if (this.state.data.RegistrationTime) {
                extraProps.last = this.state.data.RegistrationTime;
            }
        }

        return (
            <Qtooltip {...extraProps} iconState={iconState} />
        );
    }
}

export function expandRegistration(msg) {
    var out = getErrorHelpSingleMessage(msg);

    // Show the original output from the SIP device in italics, then our
    // explaination of what happened.
    return <span><em>{msg}</em>. {out}</span>;
}

import React, { Component } from 'react';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';

import { cmsGet } from '../../CallMSAPI.js';

import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

var _ = require('lodash');

class BrandingTemplatesReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            pageCount: -1
        }

        this.getColumns = this.getColumns.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lastAccountRefreshTime !== this.props.lastAccountRefreshTime) {
            this.refReactTable.fireFetchData();
        }
    }

    getColumns = () => {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: 'service_name',
                Header: 'Template Name',
                accessor: function (x) {
                    return x.Name
                },
            }
        );

        columns.push(
            {
                id: 'service_variant',
                Header: 'Service',
                accessor: function (x) {
                    return x.ServiceVariantName
                },
                sortable: false,
                className: 'col-status-light',
                Filter: ({ filter, onChange }) => self.getServiceVariantsFilters(
                    self.props.serviceVariants,
                    filter,
                    onChange
                )
            },
        );

        columns.push(
            {
                id: 'location',
                Header: 'Location',
                accessor: function (x) {
                    return x.Location
                },
                sortable: false,
                filterable: false
            },
        );

        columns.push(
            {
                id: 'template_id',
                accessor: function (x) {
                    return (
                        <Link key='templates' to={`/accounts/${self.props.account.Id}/branding/templates/edit/${x.Id}`}><i className='fa-solid fa-pencil'></i></Link>
                    )
                },
                sortable: false,
                filterable: false,
                width: 50,
            },
        );


        return columns;
    }

    getServiceVariantsFilters = (serviceVariants, filter, onChange) => {
        var opts = _.map(serviceVariants, function (s) { return (<option key={s.Id} value={s.Id}>{s.Name}</option>) });

        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}>
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

    getData = (state) => {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'pbxtemplates',
            Scope: 'OwnedTemplate',
            ServiceVariantId: null,
            sortBy: 'Name',
            sortDirection: state.sorted.length > 0
                ? state.sorted[0].desc === false
                    ? 'Ascending'
                    : 'Descending'
                : 'Ascending',
            pageSize: state['pageSize']
                ? state['pageSize']
                : null,
            currentPage: state['page']
                ? state['page'] + 1
                : null
        };

        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                if (f.id === 'service_name') {
                    apiParams['searchText'] = f.value;
                }

                if (f.id === 'service_variant') {
                    apiParams['ServiceVariantId'] = f.value;
                }
            })
        }
      
        cmsGet(
            apiParams,
            function (response) {
                self.setState({
                    data: response.Results,
                    updateTime: new Date().getTime(),
                    pageCount: response.PageCount,
                    loading: false
                });  
            },
            function (error) {
                toast.error('Unable to load table data: ' + error);
            }
        );
    }

    render = () => {
        var self = this;
        const data = this.state.data;
        var columns = self.getColumns();

        return (
            <>
                <ReactTable
                    data={data}
                    pages={this.state.pageCount}
                    resizable={false}
                    filterable
                    columns={columns}
                    minRows={0}
                    showPageJump={false}
                    multiSort={false}
                    loading={this.state.loading}
                    loadingText={'Loading...'}
                    noDataText={"You don't have any templates yet. Click on one of the add buttons above to get started."}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    className='-striped -highlight'
                    manual
                    onFetchData={function (state) {
                        self.getData(state);
                    }}
                    ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                    style={{ zIndex: '0' }}
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingTemplatesReactTable);

import React, { Component } from 'react';
import {
    getErrMsg,
    cmsGetServices,
    cmsGetSubscriptionOffers,
    cmsGetPromise,
    cmsPostPromise,
    getBaseOrderInfo
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import NewOrderForm from './NewOrderForm';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import PlaceOrder from './PlaceOrder';

import {
    DOMAIN_CODE,
    PBX_USER,
    PBX_USER_LICENCE_CODE,
    TRUNK_USER,
    TRUNK_USER_LICENCE_CODE
} from '../LicenceHelpers';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');
var moment = require('moment-timezone');

class AccountSubscriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offers: null,
            subscriptions: null,
            commercialSubs: [],
            openModal: null,
            onlyActive: true,
            orderInProgress: false,

            services: [],
            hasActiveService: false,
            hasActiveSFBEnterpriseService: false,
            accountServicesLoaded: false,

            globalCanAction: (
                props.contractAccount.purchasedEnabled === true
                && props.hasRole('PlaceOrders')
            )
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.processSubscriptionUpdate = this.processSubscriptionUpdate.bind(this);
        this.processNewSubscriptionsForm = this.processNewSubscriptionsForm.bind(this);
        this.toggleActiveOnly = this.toggleActiveOnly.bind(this);
        this.fullClose = this.fullClose.bind(this);
    }

    showModal(entry) {
        this.setState({
            openModal: entry.Id
        })
    }

    hideModal(entry) {
        this.setState({
            openModal: null,
            orderInProgress: false
        })
    }

   componentDidMount() {
        this.getSubscriptions();
        this.getOffers();
        this.getServices();
    }

    getOffers() {
        var self = this;
        cmsGetSubscriptionOffers(
            this.props.contractAccount.Id,
            function (offers) {
                self.setState({
                    offers: offers.Results
                });
            }, function (error) {
                if(showConsoleLogs()){
                    console.log("Unable to retrieve any account offers");
                }
                self.setState({
                    offers: []
                });
            }
        );
    }

    getServices() {
        var self = this;
        cmsGetServices(
            self.props.targetAccount.Id,
            function (callmsData) {
                var services = callmsData.Results;
                var hasActiveService = services.length > 0;
                var hasActiveSFBService = false;

                // Look for an SfB enterprise service...
                services.forEach(function(s) {
                    if (s.ServiceCode === 'sfb') {
                        hasActiveSFBService = true;
                    }
                });

                self.setState({
                    services: services,
                    hasActiveService: hasActiveService,
                    hasActiveSFBEnterpriseService: hasActiveSFBService,
                    accountServicesLoaded: true
                });
            },
            function (error) {
                toast.error(error);
            }
        );
    }

    toggleActiveOnly() {
        this.setState(prevState => ({
            onlyActive: !prevState.onlyActive
        }), function () {
            this.getSubscriptions();
        });
    }

    getSubscriptions() {
        var self = this;
        var commercialSubs = cmsGetPromise({
            accountId: this.props.contractAccount.Id,
            objectType: 'subscriptions',
            targetAccountId: this.props.targetAccount.Id,
            State: (self.state.onlyActive ? 'Active' : null),
        });

        var allSubs = cmsGetPromise({
            accountId: this.props.targetAccount.Id,
            objectType: 'targetsubscriptions',
            State: (self.state.onlyActive ? 'Active' : null),
        });

        Promise.all([commercialSubs, allSubs]).then(
            function (data) {
                var subs = data[0].data;
                var allSubs = data[1].data;

                self.setState({
                    subscriptions: allSubs.Results,
                    commercialSubs: subs.Results
                });
            }, function (error) {
                toast.error("Unable to retrieve account subscriptions");
                if(showConsoleLogs()){
                    console.log(error);
                }
            }
        );
    }

    updateSubscriptionQuantity(entry) {
        entry['NewQuantity'] = parseInt(entry.TempQuantity);
        this.hideModal();

        this.props.setUnsavedChanges && this.props.setUnsavedChanges( this.updatesPending() );
    }

    modalCloseNoSave(entry) {
        delete entry.TempQuantity;
        this.hideModal();
    }

    cancelSubscription(entry) {
        var self = this;

        var msg = "Are you sure you want to cancel the subscription for "
            + entry['_FullOffer'].PlanName + "?";

        if (self.props.targetAccount) {
            var userBlock = null;
            if (entry.PlanCode === PBX_USER) {
                userBlock = _.find(self.props.targetAccount.Licences, { 'ProductCode': PBX_USER_LICENCE_CODE });
            } else if (entry.PlanCode === TRUNK_USER) {
                userBlock = _.find(self.props.targetAccount.Licences, { 'ProductCode': TRUNK_USER_LICENCE_CODE });
            }

            if (userBlock) {
                var remove = entry.Quantity;
                var avail = userBlock['NumPurchased'] - userBlock['NumUsed'];

                if (avail < remove) {
                    var impact = Math.abs(avail - remove);
                    msg += " This will result in " + impact + " active users being unlicenced and unable to make calls.";
                }
            }
        }

        if (window.confirm(msg)) {
            var changes = [{
                "SubscriptionId": entry.Id,
                "State": "Cancelled"
            }];

            var baseOrder = getBaseOrderInfo(self.props.contractAccount.Id, self.props.targetAccount.Id);
            baseOrder["StateChangeItems"] = changes;
            cmsPostPromise(baseOrder).then(function (ok) {
                toast.success("Subscription cancelled");

                // Refresh the data...
                self.getSubscriptions();
                self.getOffers();
            }, function (err) {
                toast.error(getErrMsg(err));
            })
        }
    }

    processNewSubscriptionsForm(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;

        var baseOrder = getBaseOrderInfo(self.props.contractAccount.Id, self.props.targetAccount.Id, values['OrderReference']);
        baseOrder["NewItems"] = values['changes'];

        cmsPostPromise(baseOrder).then(function (ok) {
            toast.success("New order placed");

            // Refresh the data...
            self.getSubscriptions();
            self.getOffers();

            setSubmitting && setSubmitting(false);
            resetForm && resetForm();
        }, function (err) {
            setSubmitting && setSubmitting(false);
            toast.error(getErrMsg(err));
        })
    }

    processSubscriptionUpdate(optBaseReference = null) {
        var self = this;

        this.setState({
            orderInProgress: true
        });

        // TODO: Confirm agree to terms and conditions?
        var changes = [];
        this.state.commercialSubs.forEach(function (sub) {
            if (sub.hasOwnProperty('NewQuantity') && sub.NewQuantity !== sub.Quantity) {
                changes.push({
                    "SubscriptionId": sub.Id,
                    "Quantity": sub.NewQuantity,
                })
            }

        });

        var baseOrder = getBaseOrderInfo(self.props.contractAccount.Id, self.props.targetAccount.Id, optBaseReference);
        baseOrder["QuantityChangeItems"] = changes;

        cmsPostPromise(baseOrder).then(function (ok) {
            self.hideModal();
            toast.success("Subscriptions updated");

            // Refresh the data...
            self.getSubscriptions();
            self.getOffers();

            // Clear changes so row can be closed
            self.props.clearChanges && self.props.clearChanges();
        }, function (err) {
            self.hideModal();
            toast.error(getErrMsg(err));
            self.props.setUnsavedChanges && self.props.setUnsavedChanges(self.updatesPending());
        })
    }

    updatesPending() {
        var pending = false;
        this.state.commercialSubs.forEach(function (s) {
            if (s.hasOwnProperty('NewQuantity') && s.NewQuantity !== s.Quantity) {
                pending = true;
            }
        })

        return pending;
    }

    fullClose() {
        var self = this;

        if (this.props.clearChanges) {
            this.props.clearChanges(
                function () {
                    self.props.closeCallback && self.props.closeCallback();
                }
            )
        }
    }

    render() {
        var self = this;

        if (this.state.subscriptions === null || this.state.offers === null) {
            return <p>Loading</p>;
        }
        if (this.state.offers === 0 && this.state.subscriptions === 0) {
            return <p>No subscriptions or offers available.</p>;
        }

        var tableRows = [];
        var hasTotal = false;
        var willHavePriceColumns = self.state.commercialSubs.length ? true : false;

        this.state.subscriptions.forEach(function (entry, ind) {
            var tempEntry = _.find(self.state.commercialSubs, {Id: entry.Id });
            if (tempEntry) {
                entry = tempEntry;
            }

            let created = (entry.CreatedOn ? moment.tz(entry.CreatedOn, "UTC") : null);
            let modified = (entry.ModifiedOn ? moment.tz(entry.ModifiedOn, "UTC") : null);
            let end = moment.tz(entry.EndDate, "UTC");
            let offer = _.find(self.state.offers, function (o) { return o.Id === entry.OfferId });

            if (offer) {
                entry['_FullOffer'] = offer;
                if (offer.Description) {
                    entry.Description = offer.Description;
                }
            }

            let currencySymbol = entry.CurrencyCode;

            var state = entry.State;
            if (entry.NextOfferCode === null && entry.State === 'Active') {
                state = <span className="error">Active, expiring {end.fromNow()}</span>
            }

            var price = null;
            if (entry.UnitPrice) {
                hasTotal = true;
                price = <span>{currencySymbol} {(entry.Quantity * entry.UnitPrice).toFixed(2)}</span>;
            }

            var quantity = entry.Quantity;
            entry.TempQuantity = (entry.hasOwnProperty('NewQuantity') ? entry.NewQuantity : entry.Quantity);
            if (entry.hasOwnProperty('NewQuantity')) {
                quantity = (
                    <>
                    <strike>{quantity}</strike>
                    &nbsp;
                        <strong>{entry.NewQuantity}</strong>
                    </>
                );

                if (price) {
                    price = (
                        <>
                        <strike>{price}</strike>
                        &nbsp;
                        <strong>{currencySymbol} {(entry.NewQuantity * entry.UnitPrice).toFixed(2)}</strong>
                        </>
                    );
                }
            }

            if (price) {
                price = (
                    <>
                    <td className="text-right">{entry.CurrencyCode} {entry.UnitPrice.toFixed(2)}</td>
                    <td className="text-right">{price}</td>
                    </>
                );
            } else if (willHavePriceColumns) {
                price = <td colSpan="2"></td>
            }

            var title = (created ? 'Created: ' + created.fromNow() + '. ' : '');
            title += (modified ? 'Last modified: ' + modified.fromNow() + '. ' : '');
            title += 'External ID: ' + (entry.ExternalId ? entry.ExternalId : '[none]');

            var canAction = (self.state.globalCanAction
                && entry.hasOwnProperty('UnitPrice')
                && entry.hasOwnProperty('_FullOffer')
                && entry._FullOffer
            );
            var quantityChangeDialog = null;
            if (canAction) {
                quantityChangeDialog = (
                    <Dialog
                        hidden={self.state.openModal === null || self.state.openModal !== entry.Id}
                        onDismiss={() => self.modalCloseNoSave(entry)}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Update Quantity',
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 450 } },
                        }}
                    >
                        <p><strong>New Quantity</strong>
                            <input className="form-control" type="number" min={entry._FullOffer.MinQuantity} max={entry._FullOffer.MaxQuantity} defaultValue={entry.TempQuantity}
                                onChange={(e) => { entry.TempQuantity = e.currentTarget.value; }}
                            />
                        </p>

                        <DialogFooter>
                            <DefaultButton onClick={() => self.modalCloseNoSave(entry)} text="Cancel" />
                            <PrimaryButton onClick={() => self.updateSubscriptionQuantity(entry)} text="Update Quantity" />
                        </DialogFooter>
                    </Dialog>
                );
            }

            // If no active service then subscriptions can be cancelled.
            var canCancel = canAction;
            var sfbCheck = (entry.PlanCode === DOMAIN_CODE ? true : false);
            if (self.state.accountServicesLoaded) {
                if (sfbCheck && self.state.hasActiveSFBEnterpriseService) {
                    canCancel = false;
                }
            }

            tableRows.push(
                <tr key={entry.Id} title={title}>
                    <td>
                        {entry.Description ? entry.Description : entry.PlanName}
                    </td>
                    <td>
                        {state}
                        &nbsp;
                        {canAction && canCancel && entry.State === 'Active'
                            ? <button className="btn btn-xs" onClick={() => self.cancelSubscription(entry)}>
                                <i className="fa-solid fa-trash"></i>
                            </button>
                            : null}
                    </td>
                    <td className="text-right">
                        {quantity}
                    </td>
                    <td>
                        {canAction && entry.State === 'Active'
                            ? (
                                <>
                                <button className="btn btn-xs" onClick={() => self.showModal(entry)}>
                                    <i className="fa-solid fa-pencil"></i>
                                </button>&nbsp;
                                </>
                            )
                            : null}

                        {quantityChangeDialog}
                    </td>
                    {price}
                </tr>
            );
        })

        var priceCol = null;
        if (hasTotal) {
            priceCol = (
                <>
                    <th className="text-left col-unit">Unit Price</th>
                    <th className="text-left col-total">Total</th>
                </>
            );
        }

        var noUpdate = !this.updatesPending();
        var mainContent = <p>You do not currently have any subscriptions</p>;
        var updateButton = null;
        if (self.state.globalCanAction) {
            updateButton = <button className="btn btn-primary right-action-button" onClick={() => this.showModal({ Id: 'order' })} disabled={noUpdate}>Update Subscriptions</button>;
        }
        if (tableRows.length > 0) {
            mainContent = (
                <>
                    <table className="table order-table">
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Status</th>
                                <th className="text-left col-quantity">Quantity</th>
                                <th></th>
                                {priceCol}
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="user-form-action-buttons">
                            {updateButton}
                            <button className="btn right-action-button mb-2 close-form" onClick={this.fullClose}>{this.props.hasUnsavedChanges ? "Cancel" : "Close"}</button>
                        </div>
                    </div>
                </>
            );
        }

        var updateModal = (
            <PlaceOrder
                isOpen={self.state.openModal !== null && self.state.openModal === 'order'}
                subs={self.state.commercialSubs}
                orderCallback={(reference) => self.processSubscriptionUpdate(reference)}
                closeCallback={self.hideModal}
                disableOrderButton={self.state.orderInProgress}
            />
        );

        var activeButtonText = 'Viewing Active Only';
        if (!self.state.onlyActive) {
            activeButtonText = 'Viewing All Subscriptions';
        }

        var newOrderForm = null;
        if (this.props.contractAccount.purchasedEnabled === true) {
            newOrderForm = (
                <>
                    {this.state.offers.length ? <hr /> : null}
                    <NewOrderForm
                        submitCallback={this.processNewSubscriptionsForm}
                        submitButtonText="Order Subscriptions"
                        subscriptions={this.state.commercialSubs}
                        allSubscriptions={this.state.subscriptions}
                        offers={this.state.offers}
                        limitSubscriptions={self.props.limitSubscriptions}
                    />
                </>
            );
        }

        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Subscriptions</h4>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className="btn btn-default" onClick={self.toggleActiveOnly}>{activeButtonText}</button>
                    </div>
                </div>
                {mainContent}
                {updateModal}

                {newOrderForm}
            </>
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount,
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountSubscriptions);

import React, { Component } from 'react';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import ActionHeader from '../ActionHeader';
import { serviceCodeToIconClass } from '../CallMSUIHelpers';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

var moment = require('moment-timezone');
var _ = require('lodash');

class ProblemCallsModal extends Component {
    showModal() {
        this.setState({ hideDialog: false });
    }

    hideModal() {
        this.setState({ hideDialog: true });
    }

    render() {
        var self = this;
        var rows = [];
        this.props.calls.forEach(function (call) {
            var created = call.CreatedOn;
            var title = call.CreatedOn;
            let t = moment.tz(call.CreatedOn, "UTC");
            if (t.isValid()) {
                created = t.fromNow();
                title = t.local().format('MMM D YYYY, H:mm:ss');
            }

            var post = '';
            if (call.Domain) {
                post += '@' + call.Domain;
            }

            var icon = null;
            var service = _.find(self.props.services, function (s) {
                return (s.ServiceId === call.ServiceId);
            });
            if (service) {
                icon = <i className={serviceCodeToIconClass(service.ServiceCode)}></i>;
            }

            var reason = "Unknown Error.";
            if (call.Reason && call.Reason !== "") {
                reason = call.Reason;
            }

            rows.push(
                <tr key={call.Id}>
                    <td title={title}>{created}</td>
                    <td>{icon}</td>
                    <td>{call.Identifier}{post}</td>
                    <td>{reason}</td>
                </tr>
            );
        });

        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.props.closeCallback}
                isBlocking={false}
            >
                <>
                    <div className="problem-call-table-wrapper">
                    <ActionHeader headerText="Unconfigured User Calls" smallHeader={true}>
                        <button className="btn btn-default" onClick={this.props.closeCallback}>
                            <Icon iconName="Cancel" className="fa-lg"/>
                        </button>
                    </ActionHeader>
                    <hr />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Created On</th>
                                <th colSpan="2">Identifier</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    </div>
                </>
            </Modal>
        );
    }

}

export default ProblemCallsModal;

import React, { Component } from 'react';
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import { cmsGetAllAdminUsersHistoryPromise } from '../CallMSAPI';
import AdminUserRoleHistoryTable from './AdminUserRoleHistoryTable';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from 'react-redux';

var _ = require('lodash');
var moment = require('moment-timezone');

class Audit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            userData: [],
            pageCount: -1,
            expanded: {},
        }

        this.updateData = this.updateData.bind(this);
        this.userHistoryTableRef = React.createRef();
        this.closeAllRows = this.closeAllRows.bind(this);
        this.handleRowExpanded = this.handleRowExpanded.bind(this);
    }

    updateData(state, instance) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'adminusers/history'
        };

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state.hasOwnProperty('page')) {
            // js from 0, api starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        if (state.sorted.length > 0) {
            switch (state.sorted[0].id) {
                case 'name':
                    apiParams['SortBy'] = 'FirstName';
                    break;
                case 'email':
                    apiParams['SortBy'] = 'Email'
                    break;
                case 'alteredon':
                    apiParams['SortBy'] = 'AlteredOn';
                    break;
                default:
                    apiParams['SortBy'] = 'AlteredOn';
            }

            apiParams['SortDirection'] = state.sorted[0].desc === false
                ? 'Ascending'
                : 'Descending'; 
        }

        cmsGetAllAdminUsersHistoryPromise(apiParams).then(function (res) {
            if (res && res.data && res.data.Results) {
                self.setState({
                    userData: res.data.Results,
                    pageCount: res.data.PageCount,
                    loading: false
                });

            }
        }).catch(function (err) {
            toast.error(err);
        })
    }

    closeAllRows() {
        // Ditch all expanded rows...
        this.setState({
            expanded: {},
        });
    }

    handleRowExpanded(newExpanded, index, event) {
        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
            });
        }
    }

    render() {
        var self = this;
        const data = this.state.userData;

        return (
            <>
                <h4> Admin Users</h4>
                <br />
                <div>
                    <ReactTable
                        data={data}
                        expanded={this.state.expanded}
                        onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
                        pages={this.state.pageCount}
                        resizable={false}
                        columns={[
                            {
                                id: "name",
                                Header: "Name",
                                accessor: function (d) {
                                    return d.FirstName + ' ' + d.LastName;
                                },
                                width: 200,
                                filterable: false
                            },
                            {
                                id: "email",
                                Header: "Email",
                                accessor: function (d) {
                                    return d.Email;
                                },
                                width: 200,
                                filterable: false
                            },
                            {
                                id: "status",
                                Header: "Status",
                                className: "text-center",
                                accessor: function (d) {
                                    return d.Status;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: "alteredon",
                                Header: "Altered On",
                                className: "text-center",
                                accessor: function (d) {
                                    let t = moment.tz(d.AlteredOn, "UTC");
                                    if (t.isValid()) {
                                        if (t.year() === moment().year()) {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                        } else {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                        }
                                    } else {
                                        return d.ModifiedOn;
                                    }
                                },
                                filterable: false,
                                width: 150
                            },
                            {
                                id: "modifiedby",
                                Header: "Modified By",
                                className: "text-center",
                                accessor: function (d) {
                                    return d.ModifiedBy;
                                },
                                sortable: false,
                                filterable: false,
                            },
                        ]
                        }
                        minRows={0}
                        showPageJump={false}
                        updateData={self.updateData}
                        multiSort={false}
                        loading={false}
                        loadingText={"Loading..."}
                        noDataText={"You don't have any Admin User Change History."}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        pageSizeOptions={[5, 10, 20]}
                        className="-striped -highlight"
                        // Tell react-table that we'll be sorting pagination and sorting via server side
                        manual
                        // Important to allow manual updating
                        ref={this.userHistoryTableRef}
                        SubComponent={row => {
                            var userAdminId = null;
                            if (row.original.Id) {
                                userAdminId = row.original.Id;
                            }

                            if (userAdminId) {
                                return (<AdminUserRoleHistoryTable adminUserId={userAdminId} />);
                            } else {
                                return ('No records to show');
                            }

                        }}
                        onFetchData={function (state, instance) {
                            self.updateData(state, instance);
                        }}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Audit);

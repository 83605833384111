import React, { Component } from 'react';
import {
    cmsGetPromise,
    getErrMsg
} from '../../CallMSAPI.js';
import { toast } from 'react-toastify';
import ExpandingReactTable from '../../ExpandingReactTable';
import { connect } from 'react-redux';

var moment = require('moment-timezone');
var _ = require('lodash');

class BillingAuditTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},

        };

        this.updateData = this.updateData.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    updateData(state, instance) {
        var self = this;

        self.setState({
            loading: true
        }, function () {
            var apiParams = {
                accountId: this.props.contractAccount.Id,
                objectType: 'orders',
            };

            if (state.sorted.length > 0) {
                apiParams['SortBy'] = 'OrderDate';

                if (state.sorted[0].id === 'targetaccountname') {
                    apiParams['SortBy'] = 'TargetAccountName';
                }
                if (state.sorted[0].id === 'userprincipalname') {
                    apiParams['SortBy'] = 'UserPrincipalName';
                }

                if (state.sorted[0].desc === false) {
                    apiParams['SortDirection'] = 'Ascending';
                } else {
                    apiParams['SortDirection'] = 'Descending';
                }
            }

            if (state.filtered.length > 0) {
                state.filtered.forEach(function (f) {
                    // Our version of normal search text...
                    if (f.id === 'reference') {
                        apiParams['OrderReference'] = f.value;
                        return;
                    }

                    // Next, API level filters
                    if (f.id === 'targetaccountname') {
                        // Need to get the service ID from this.
                        apiParams['TargetAccountId'] = f.value;
                        return;
                    }
                })
            }

            // Handle pagination
            if (state['pageSize']) {
                apiParams['pageSize'] = state['pageSize'];
            }

            if (state['page']) {
                // JS from 0, API starts at 1
                apiParams['currentPage'] = state['page'] + 1;
            }

            cmsGetPromise(apiParams).then(
                function (result) {
                    if (result.data) {
                        self.setState({
                            data: result.data.Results,
                            pageCount: result.data.PageCount,
                            loading: false
                        });
                    }
                }, function (err) {
                    self.setState({ loading: false });
                    toast.error("Unable to request order data: " + getErrMsg(err));
                }
            );
        });
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "orderdate",
                Header: <p>Order Date</p>,
                accessor: function (order) {
                    var t = moment.tz(order.OrderDate, "UTC");
                    return (
                        <>
                            <span>{t.format("D MMMM YYYY")}</span>
                        </>
                    );
                },
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                id: "targetaccountname",
                Header: <p>Target Account</p>,
                accessor: function (order) {
                    return order.TargetAccountName
                },
                sortable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.accountFilter(
                    self.props.allAccounts,
                    filter,
                    onChange,
                    true
                )
            }
        );

        columns.push(
            {
                id: "userprincipalname",
                Header: <p>User</p>,
                accessor: function (order) {
                    return order.UserPrincipalName
                },
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                id: "reference",
                Header: <p>Order Reference</p>,
                accessor: function (order) {
                    return order.OrderReference
                },
                sortable: false,
                filterable: true
            }
        );


        columns.push(
            {
                id: "itemcount",
                className: "text-right",
                Header: <p className="text-left">Items</p>,
                accessor: function (order) {
                    return order.Items.length
                },
                sortable: false,
                filterable: false
            }
        );

        return columns;
    }

    render() {
        var self = this;

        return (
            <ExpandingReactTable
                pages={this.state.pageCount}
                data={self.state.data}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"No billing data available."}
                updateData={self.updateData}
                defaultSorted={[
                    {
                        id: "started",
                        desc: true
                    }
                ]}
                columns={self.getColumns()}
                SubComponent={row => {
                    var { loadCompleteCallback, loadStartingCallback, setRefreshPause, closeForm, services, ...otherProps } = { ...self.props };

                    var data = row.original;

                    var rows = [];
                    _.forEach(data.Items, function (i) {
                        var cols = [];
                        cols.push(<td key="type">{i.Type}</td>);
                        cols.push(<td key="subscription_id">{i.SubscriptionId}</td>);

                        var qChange = (i.PreviousQuantity && i.PreviousQuantity !== i.NewQuantity ? true : false);
                        cols.push(
                            <td key="quantity" className={qChange ? "has-change" : ""}>
                                {i.PreviousQuantity && i.PreviousQuantity !== i.NewQuantity ? <span className="old-value"> {i.PreviousQuantity} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewQuantity}
                            </td>
                        );

                        var sChange = (i.PreviousState && i.PreviousState !== i.NewState ? true : false);
                        cols.push(
                            <td key="state" className={sChange ? "has-change" : ""}>
                                {i.PreviousState && i.PreviousState !== i.NewState ? <span className="old-value"> {i.PreviousState} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewState}
                            </td>
                        );

                        var cChange = (i.PreviousOfferCode && i.PreviousOfferCode !== i.NewOfferCode ? true : false);
                        cols.push(
                            <td key="offer" className={cChange ? "has-change" : ""}>
                                {i.PreviousOfferCode && i.PreviousOfferCode !== i.NewOfferCode ? <span className="old-value"> {i.PreviousOfferCode} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewOfferCode}
                            </td>
                        );

                        var pChange = false;
                        if ((i.PreviousCurrencyCode && i.PreviousCurrencyCode !== i.NewCurrencyCode)
                            || (i.PreviousUnitPrice && i.PreviousUnitPrice !== i.NewUnitPrice)
                        ) {
                            pChange = true;
                        }

                        cols.push(
                            <td key="price" className={pChange ? "has-change" : ""}>
                                {i.PreviousCurrencyCode && i.PreviousCurrencyCode !== i.NewCurrencyCode ? <span className="old-value"> {i.PreviousCurrencyCode} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewCurrencyCode}
                                &nbsp;
                                {i.PreviousUnitPrice && i.PreviousUnitPrice !== i.NewUnitPrice ? <span className="old-value"> {i.PreviousUnitPrice.toFixed(2)} <i className="fa-solid fa-arrow-right"></i> </span> : null}
                                {i.NewUnitPrice.toFixed(2)}
                            </td>
                        );

                        rows.push(
                            <tr className={"audit-order-item-row order-item-" + i.Type}>
                                {cols}
                            </tr>
                        )
                    });

                    var t = moment.tz(data.OrderDate, "UTC");
                    return (
                        <div className="order-item-wrapper">
                            <p><strong>Order Details: {data.OrderReference ? data.OrderReference : null}</strong></p>
                            <ul>
                                <li><span>{t.format("MMMM Do YYYY, h:mm:ss a")} (UTC)</span></li>
                                <li><span>Target Account:</span> {data.TargetAccountName}</li>
                                <li><span>Owner Account:</span> {data.OwnerAccountName}</li>
                                <li><span>User (UPN):</span> {data.UserPrincipalName}</li>
                                <li><span>User Name:</span> {data.UserName}</li>
                                {data.ExternalUserId ? <li><span>External User Id:</span> {data.ExternalUserId}</li> : null}
                                <li><span>IP Address:</span> {data.IPAddress}</li>
                                <li><span>User Agent:</span> {data.UserAgent}</li>
                            </ul>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Subscription Id</th>
                                        <th>Quantity</th>
                                        <th>State</th>
                                        <th>Offer Code</th>
                                        <th>Unit Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>

                        </div>
                    );
                }}
            />
        );
    }

    accountFilter(allAccounts, filter, onChange) {
        var opts = _.map(
            _.orderBy(allAccounts, 'Name', 'asc'),
            function (s) {
                return (<option key={s.Id} value={s.Id}>{s.Name}</option>)
            }
        );

        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingAuditTable);

import React, { Component } from 'react';
import { getErrMsg, cmsDeleteAPIKeyPromise, cmsGetApiKeysPromise } from '../CallMSAPI.js';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import ExpandingReactTable from '../ExpandingReactTable';
import EditAPIRolesForm from "./EditAPIRolesForm";
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
var moment = require('moment-timezone');

class APIKeyReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},
        }

        this.updateData = this.updateData.bind(this);
        this.tableRef = React.createRef();
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            if(showConsoleLogs()){
                console.log("Starting an update");
            }
            self.tableRef.current.forceTableUpdate();
        }
    }

    updateData(state, instance) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'apikeys'
        };

        // Handle sorting/order
        //apiParams['SortBy'] = 'FirstName';
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'name') {
                apiParams['SortBy'] = 'Name';
            } else if (state.sorted[0].id === 'id') {
                apiParams['SortBy'] = 'Id';
            }
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Our version of normal search text...
                if (f.id === 'name') {
                    apiParams['searchText'] = f.value;
                    return;
                }
            });
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGetApiKeysPromise(
            apiParams
        ).then(
            function (resp) {
                var callmsData = resp.data;
                self.setState({
                    data: callmsData.Results,
                    pageCount: callmsData.PageCount,
                    loading: false
                });
                self.props.loadCompleteCallback && self.props.loadCompleteCallback();
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    deleteApiKey(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete the API Key '" + data.Name + "'?")) {
            cmsDeleteAPIKeyPromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("API key deleted");
            }, function (err) {
                toast.error("Unable to delete API key: " + getErrMsg(err));
            }).finally(function () {
                // Go up the chain in case other things rely on this refresh
                self.props.apiUpdateTrigger && self.props.apiUpdateTrigger();
            })
        }
    }

    render() {
        var self = this;
        const data = this.state.data;

        return (
            <ExpandingReactTable
                data={data}
                pages={this.state.pageCount}
                expanded={this.state.expanded}
                onExpandedChange={this.handleRowExpanded}
                resizable={false}
                filterable
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            return d.Name;
                        },
                        width: 200
                    },
                    {
                        id: "roles",
                        Header: "Roles",
                        accessor: function (d) {
                            return (
                                <span title={d.RoleNames.join(", ")}>{d.RoleNames.join(", ")}</span>
                            );
                        },
                        filterable: false
                    },
                    {
                        id: "createdon",
                        Header: "Created On",
                        accessor: function (d) {
                            let t = moment.tz(d.CreatedOn, "UTC");
                            if (t.isValid()) {
                                if (t.year() === moment().year()) {
                                    return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                } else {
                                    return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                }
                            } else {
                                return d.CreatedOn;
                            }
                        },
                        sortable: false,
                        filterable: false,
                        width: 150
                    },
                    {
                        id: "delete",
                        Header: "",
                        className: "text-right",
                        width: 50,
                        accessor: function (d) {
                            return (
                                <button onClick={(e) => self.deleteApiKey(e, d)} className="btn btn-xs btn-default">
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            );
                        },
                        filterable: false
                    }
                ]
                }
                defaultSorted={[{
                    id: 'name',
                    desc: true,
                }]}
                minRows={0}
                showPageJump={false}
                updateData={self.updateData}
                multiSort={false}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"You don't have any API Keys."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                // Important to allow manual updating
                ref={this.tableRef}
                SubComponent={row => {
                    return (
                        <EditAPIRolesForm
                            closeForm={() => self.closeAllRows()}
                            apiKey={row.original}
                            closeButtonText={"Close"}
                            submitButtonText={"Save"}
                        />
                    );
                }}
            />
        );
    }

    closeAllRows = () => {
        // Ditch all expanded rows...
        this.setState({
            expanded: {},
        });
    }

    handleRowExpanded = (newExpanded, index, event) => {
        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
            });

            // Mark a refresh in progress to stop updates
            this.props.setRefreshPause && this.props.setRefreshPause(true);
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(APIKeyReactTable);

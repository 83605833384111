import React, { Component } from 'react';
import { getErrMsg, cmsGet, cmsDeleteAdminUserPromise } from '../CallMSAPI.js';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import ExpandingReactTable from '../ExpandingReactTable';
import EditAdminUserForm from "./EditAdminUserForm";
import "react-table/react-table.css";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class AdminUserReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},
        }
        this.tableRef = React.createRef();
        this.updateData = this.updateData.bind(this);
        this.deleteAdminUser = this.deleteAdminUser.bind(this);
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            if(showConsoleLogs()){
                console.log("Starting an update");
            }
            self.tableRef.current.forceTableUpdate();
        }
    }

    updateData(state, instance) {
        var self = this;
        self.props.loadStartingCallback && self.props.loadStartingCallback();

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'adminusers'
        };

        // Handle sorting/order
        //apiParams['SortBy'] = 'FirstName';
        if (state.sorted.length > 0) {
            if (state.sorted[0].id === 'name') {
                apiParams['SortBy'] = 'FirstName';
            } else if (state.sorted[0].id === 'email') {
                apiParams['SortBy'] = 'UPN';
            }
            if (state.sorted[0].desc === false) {
                apiParams['SortDirection'] = 'Ascending';
            } else {
                apiParams['SortDirection'] = 'Descending';
            }
        }

        // Handle filtering (inc search)
        if (state.filtered.length > 0) {
            state.filtered.forEach(function (f) {
                // Our version of normal search text...
                if (f.id === 'name' || f.id === 'email') {
                    apiParams['searchText'] = f.value;
                    return;
                }
            });
        }

        // Handle pagination
        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state['page']) {
            // JS from 0, API starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGet(
            apiParams,
            function (callmsData) {
                self.setState({
                    data: callmsData.Results,
                    pageCount: callmsData.PageCount,
                    loading: false
                });
                self.props.loadCompleteCallback && self.props.loadCompleteCallback();
            },
            function (error) {
                toast.error("Unable to load table data: " + error);
            }
        );
    }

    deleteAdminUser(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete admin user?")) {
            cmsDeleteAdminUserPromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("User deleted");
            }, function (err) {
                toast.error("Unable to delete user: " + getErrMsg(err));
            }).finally(function () {
                self.tableRef.current.forceTableUpdate();
            })
        }
    }


    closeAllRows = () => {
        // Ditch all expanded rows...
        this.setState({
            expanded: {},
        }, this.props.userUpdateTrigger);
    }

    handleRowExpanded = (newExpanded, index, event) => {
        if (this.state.expanded[index] === true) {
            this.closeAllRows();
        } else {
            this.setState({
                // we override newExpanded, keeping only current selected row expanded
                expanded: { [index]: true },
            });

            // Mark a refresh in progress to stop updates
            this.props.setRefreshPause && this.props.setRefreshPause(true);
        }
    }

    render() {
        var self = this;
        const data = this.state.data;

        return (
            <ExpandingReactTable
                data={data}
                pages={this.state.pageCount}
                expanded={this.state.expanded}
                onExpandedChange={this.handleRowExpanded}
                resizable={false}
                updateData={self.updateData}
                filterable
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            return d.FirstName + ' ' + d.LastName;
                        }
                    },
                    {
                        id: "email",
                        Header: "Email",
                        accessor: function (d) {
                            if (d.Email) {
                                return d.Email;
                            } else {
                                return d.UPN;
                            }
                        }
                    },
                    {
                        id: "roles",
                        Header: "Roles",
                        accessor: function (d) {
                            return d.RoleNames.join(", ");
                        },
                        filterable: false
                    },
                    {
                        id: "delete",
                        Header: "",
                        className: "text-right",
                        width: 50,
                        accessor: function (d) {
                            return (
                                <button onClick={(e) => self.deleteAdminUser(e, d)} className="btn btn-xs btn-default">
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            );
                        },
                        filterable: false
                    }
                ]
                }
                defaultSorted={[{
                    id: 'name',
                    desc: true,
                }]}
                minRows={0}
                showPageJump={false}
                multiSort={false}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"You don't have any administrative users yet."}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                ref={this.tableRef}
                SubComponent={row => {
                    return (
                        <EditAdminUserForm
                            closeForm={() => self.closeAllRows()}
                            adminUser={row.original}
                            closeButtonText={"Close"}
                            submitButtonText={"Save"}
                        />
                    );
                }}
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserReactTable);

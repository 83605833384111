import React from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { getErrMsg, cmsPutPromise } from '../CallMSAPI.js';
import ActionHeader from '../ActionHeader.js';
import { toast } from 'react-toastify';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {CallDebugSummary, CallDebugOutput} from '../components/call/CallDebugOutput';
import { generateCallSipDebugLink, generateServicePageURL, serviceCodeTypeToIcon } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { object, func } from 'prop-types';
import { getExampleLogLists } from '../components/SIPErrors.js';
import './CallSingle.css';
import * as moment from "moment";
var _ = require('lodash');

const CallSingle = (props) => {

    const [showDebugOutputHelp, setDebugOutputHelp] = useState(false);
    const [showDebugPrivateOutputHelp, setDebugPrivateOutputHelp] = useState(false);
    const [showCopyReportCallDialog, setCopyReportCallDialog] = useState(false);
    const [showCallIdInternalHelp, setCallIdInternalHelp] = useState(false);

    const toggleDebugOutputHelp = () => {
        setDebugOutputHelp(!showDebugOutputHelp);
    }

    const toggleDebugPrivateOutputHelp = () => {
        setDebugPrivateOutputHelp(!showDebugPrivateOutputHelp);
    }

    const dismissDebugOutputHelp = () => setDebugOutputHelp(false);

    const dismissDebugPrivateOutputHelp = () => setDebugPrivateOutputHelp(false);

    const toggleHelp = () => setCallIdInternalHelp(!showCallIdInternalHelp);

    const dismissHelp = () => setCallIdInternalHelp(false);

    const justCopiedAction = () => toast.success("Link copied to clipboard");

    const toggleDiagnosticHold = () => {

        cmsPutPromise({
            accountId: props.account.Id,
            objectIds: [
                { type: 'calls', id: props.callLogData.Id },
                { type: 'diagnostichold' }
            ],
            DiagnosticHold: !props.callLogData.DiagnosticHold
        })
            .then(props.refetchCallData)
            .catch(err => toast.error(`Unable to set diagnostic hold for call: ${getErrMsg(err)}`));
    };

    const generateDebugLink = (account, call, callId) => {
        if (account && account.Links) {
            let baseDebugUrl = _.find(account.Links, { "ExternalLinkTypeName": "sip-debug" });
            if (baseDebugUrl) {
                baseDebugUrl = baseDebugUrl.URL
                let ids = [];
                if(callId.includes(',')){
                    ids = callId.replaceAll(" ","").split(',');
                } else {
                    ids.push(callId);
                }
                let idLinks = [];
                ids.forEach((id, i) => {
                    let url = generateCallSipDebugLink(baseDebugUrl, id, call.StartedOn);
                    idLinks.push(
                        <a href={url} target="_blank" key={i}>
                            {id} (View Trace)
                        </a>
                    );
                    idLinks.push(<br/>);
                });
                return idLinks; 
            }
        }
        return callId;
    }

    const getCopyReportCallDialog = () => {
        return (
            <>
                {showCopyReportCallDialog && (
                    <Dialog
                        hidden={false}
                        onDismiss={() => setCopyReportCallDialog(false)}
                        dialogContentProps={{
                            type: DialogType.normal,
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 150 } },
                        }}>
                        <div>
                            <div style={{ textAlign: "center", fontSize: "xxx-large" }}>
                                <Icon iconName="CheckMark" style={{ color: "#6bb700" }} />
                            </div>
                            <br />
                            <p style={{ textAlign: "center" }}>This call is identified by the unique link below.</p>
                            <p style={{ textAlign: "center" }}>Copy this link and provide it to the support team by pasting it into a support ticket.</p>
                            <br />
                            <div>
                                <fieldset style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <input type="text" defaultValue={window.location.href} style={{ padding: "5px 12px", borderColor: 'rgb(16, 110, 190)' }}></input>
                                    <CopyToClipboard text={window.location.href} onCopy={justCopiedAction}>
                                        <button className="btn btn-primary">Copy</button>
                                    </CopyToClipboard>
                                </fieldset>
                            </div>
                        </div>
                    </Dialog>
                )}
            </>
        );
    }

    const {
        services,
        callLogData,
        callLogUser
    } = props;

    if (callLogData === null || services === null || callLogUser === null) {
        if (callLogData === false) {
            return (
                <>
                    <ActionHeader headerText="Call Data" />
                    <p>Call data not available.</p>
                    <p>
                        This call has now been archived or will appear shortly if it's new. 
                        Call logs are typically kept for up to 5 days but can be kept for 30 days by toggling Diagnostic hold.
                        </p>
                    <Link to={`/portal/${props.account.Id}/users`}>
                        Back to User Screen
                    </Link>
                </>
            )
        } else {
            return (
                <>
                    <ActionHeader headerText="Call Data" />
                    <p>Loading call...</p>
                </>
            )
        }
    } else {
        var call = callLogData;
        var answered = null;
        var titleAnswered = null;
        if (call && call.AnsweredOn) {
            let t = moment.utc(call.AnsweredOn);
            if (t.isValid()) {
                answered = t.fromNow();
                titleAnswered = t.local().format('MMM DD YYYY, H:mm:ss');
            } else {
                let date = moment(call.AnsweredOn, "YYYY-MM-DD HH:mm:ss");
                t = moment.utc(date);
                if (t.isValid()) {
                    answered = t.fromNow();
                    titleAnswered = t.local().format('MMM DD YYYY, H:mm:ss');
                }
            }
        }

        var started = null;
        var titleStarted = null;
        if (call && call.StartedOn) {
            let t = moment.utc(call.StartedOn);
            if (t.isValid()) {
                started = t.fromNow();
                titleStarted = t.local().format('MMM DD YYYY, H:mm:ss');
            } else {
                let date = moment(call.StartedOn, "YYYY-MM-DD HH:mm:ss");
                t = moment.utc(date);
                if (t.isValid()) {
                    started = t.fromNow();
                    titleStarted = t.local().format('MMM DD YYYY, H:mm:ss');
                }
            }
        }

        var completed = null;
        var titleCompleted = null;
        if (call && call.CompletedOn) {
            let t = moment.utc(call.CompletedOn);
            if (t.isValid()) {
                completed = t.fromNow();
                titleCompleted = t.local().format('MMM DD YYYY, H:mm:ss');
            } else {
                let date = moment(call.CompletedOn, "YYYY-MM-DD HH:mm:ss");
                t = moment.utc(date);
                if (t.isValid()) {
                    completed = t.fromNow();
                    titleCompleted = t.local().format('MMM DD YYYY, H:mm:ss');
                }
            }
        }

        var caller = call.neatCallerId;
        var callee = call.neatCalleeId;
        var userName = callLogUser.Part1.Identifier
            + ' / ' + callLogUser.Part2.Identifier;

        var fullName = '';
        var email = '';
        var icon = null;
        if (callLogUser !== null) {
            if (callLogUser.Part1.Name) {
                fullName = callLogUser.Part1.Name;
            }

            if (callLogUser.Part2.Name) {
                if (fullName === '') {
                    fullName = callLogUser.Part2.Name;
                } else if (fullName !== callLogUser.Part2.Name) {
                    fullName += ' / ' + callLogUser.Part2.Name;
                }
            }
            if (callLogUser.Part2.Email) {
                email = callLogUser.Part2.Email;

            } else if (callLogUser.Part2.UserPrincipalName) {
                // In preparation for the API returning this field
                email = callLogUser.Part2.UserPrincipalName;
            }

            if (callLogUser.Part2.ServiceUserPartType) {
                icon = serviceCodeTypeToIcon(callLogUser.Part2.ServiceUserPartType, false);
            }
        }

        var userNameSpan = (
            <span>
                {callLogUser.Part1.Identifier}
                &nbsp;/&nbsp;
                {icon}
                {callLogUser.Part2.Identifier}
                &nbsp;
                {fullName !== '' ? '(' + fullName + ')' : ''}
                {email !== '' ? <>&nbsp;{email}</> : ''}
            </span>
        );


        if (userName !== null) {
            if (call.Direction === 'FromPart1') {
                callee = userNameSpan;
            } else {
                caller = userNameSpan;
            }
        }

        // Find these in service array
        var callerService = 'Unknown Service';
        var calleeService = 'Unknown Service';

        var callerServiceUrl = null;
        var calleeServiceUrl = null;

        // In some cases, e.g. demo date, we will manual set service names
        if (call.callerServiceName && call.calleeServiceName) {
            callerService = call.callerServiceName;
            calleeService = call.calleeServiceName;
        } else {
            var part1Service = _.find(services, { 'Id': callLogUser.Part1.AccountServiceId });
            var part2Service = _.find(services, { 'Id': callLogUser.Part2.AccountServiceId });

            var callerServiceObj = null;
            var calleeServiceObj = null;

            if (call.Direction === 'FromPart1') {
                callerServiceObj = part1Service;
                calleeServiceObj = part2Service;
            } else {
                callerServiceObj = part2Service;
                calleeServiceObj = part1Service;
            }

            // Build URLs so we can fast link from a call log to the specific account service page
            callerServiceUrl = generateServicePageURL(props.account.Id, callerServiceObj);
            calleeServiceUrl = generateServicePageURL(props.account.Id, calleeServiceObj);

            callerService = callerServiceObj.Name;
            calleeService = calleeServiceObj.Name;
        }


        /**
         * Generate call summary message box. Could be new style that looks at call as a whole
         * including SIP. Or could be old style that is flat failure advice.
         */
        var callSummary = (<CallDebugSummary callLogData={call} logs={call.DebugOutput} callerServiceName={callerService} calleeServiceName={calleeService} />);
        if (callSummary === null) {
            callSummary = (call.FailureAdvice &&
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    <strong>Failure Advice:</strong> {call.FailureAdvice}
                </MessageBar>
            );
        }

        return (
            <>
                <ActionHeader headerText="Call Log">
                    <div className="call-diag-wrapper">
                    <button className={"btn btn-primary"}
                        onClick={() => {
                            if (!callLogData.DiagnosticHold) {
                                toggleDiagnosticHold();
                            }
                            setCopyReportCallDialog(true);
                        }}
                    >
                        Report Call
                    </button>
                    <Toggle
                        label="Diagnostic Hold"
                        checked={callLogData.DiagnosticHold}
                        inlineLabel
                        onText=""
                        offText=""
                        onChange={toggleDiagnosticHold}
                    />
                    </div>
                </ActionHeader>

                <hr />

                {callSummary}

                <table className="table table-call-info">
                    <tbody>
                        <tr>
                            <th className="title-column">Caller</th>
                            <td>{caller}</td>
                        </tr>
                        <tr>
                            <th>Callee</th>
                            <td>{callee}</td>
                        </tr>
                        <tr>
                            <th>Direction</th>
                            <td>
                                { callerServiceUrl ? <Link key="callerService" to={callerServiceUrl}>{callerService}</Link> : callerService}
                                &nbsp;
                                <i className="fa-solid fa-arrow-right"></i>
                                &nbsp;
                                { calleeServiceUrl ? <Link key="calleeService" to={calleeServiceUrl}>{calleeService}</Link> : calleeService}
                            </td>
                        </tr>
                        <tr>
                            <th>Disposition</th>
                            <td className="capitalize">
                                {call.Disposition}
                                {call.DispositionAdvice ? <p>{call.DispositionAdvice}</p> : null}
                            </td>
                        </tr>

                        <tr>
                            <th>Started</th>
                            <td> {titleStarted} ({started}) </td>
                        </tr>

                        <tr>
                            <th>Answered</th>
                            <td>
                                {answered !== null ? <span>{titleAnswered} ({answered})</span> : "No answer"}
                            </td>
                        </tr>

                        <tr>
                            <th>Duration (h:m:s)</th>
                            <td>
                                {answered !== null && call.Duration !== null ? <span>{call.Duration}</span> : "-"}
                            </td>
                        </tr>

                        {titleCompleted &&

                            <tr>
                                <th>Complete</th>
                                <td>{titleCompleted} ({completed})</td>
                            </tr>
                        }
                        <tr>
                            <th>Call ID (PBX)</th>
                            <td className="long-header-string">{generateDebugLink(props.baseAccount, call, call.CallIdPart1)}</td>
                        </tr>
                        {call.CallIdPart2 &&
                            <tr>
                                <th>Call ID (Microsoft)</th>
                                <td className="long-header-string">{call.CallIdPart2}</td>
                            </tr>
                        }
                        {call.CallIdPart2CorrelationId &&
                            <tr>
                                <th>Microsoft Correlation ID</th>
                                <td className="long-header-string">{call.CallIdPart2CorrelationId}</td>
                            </tr>
                        }
                        {props.hasRole('ViewCallIdInternalCallDebugOutput') &&
                            <tr>
                                <th>
                                    <div>
                                        Call ID (Internal)
                                        <button
                                            className="btn btn-link btn--faux-link"
                                            onClick={(e) => { e.preventDefault(); toggleHelp(); return false; }}
                                        >
                                            <i className="fa-solid fa-question-circle" id={"CallIdInternalHelp"}></i>
                                        </button>
                                        {showCallIdInternalHelp &&
                                            <TeachingBubble
                                                target={'#CallIdInternalHelp'}
                                                hasCondensedHeadline={true}
                                                onDismiss={dismissHelp}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"Call ID (Internal)"}>
                                                <p>This call ID is used on the internal leg in the platform and will not be
                                                    visible in SIP messages sent to the PBX/Trunk or to Microsoft.</p>
                                            </TeachingBubble>
                                        }
                                    </div>
                                </th>
                                <td className="long-header-string">{generateDebugLink(props.baseAccount, call, call.CallIdInternal)}</td>
                            </tr>
                        }
                        {props.hasRole('ViewCallDebugOutput') &&
                            <tr className="system-owner-action">
                                <th>SBC Host <i className="fa-solid fa-user-secret" title="System Owner Only"></i></th>
                                <td>{call.SBCHost}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                                <div>
                                    Call ID (PBX)<br />
                                    Debug Output
                                    <button
                                        className="btn btn-link btn--faux-link"
                                        onClick={(e) => { e.preventDefault(); toggleDebugOutputHelp(); return false; }}>
                                        <i className="fa-solid fa-question-circle" id={"DebugOutputHelp"} />
                                    </button>
                                </div>
                                {showDebugOutputHelp &&
                                    <TeachingBubble
                                        target={'#DebugOutputHelp'}
                                        hasCondensedHeadline={true}
                                        onDismiss={dismissDebugOutputHelp}
                                        hasCloseIcon={true}
                                        isWide={true}
                                        closeButtonAriaLabel="Close"
                                        headline={"What Is Included in the Debug Output?"}>
                                        <div>
                                            <p>The Debug Output shows SIP
                                            messages between the PBX/Trunk and
                                            Edge SBC. The output also includes
                                            the main call state changes, e.g.
                                            ringing, connected, closing.</p>
                                        </div>
                                    </TeachingBubble>
                                }
                            </th>
                            <td>
                                <CallDebugOutput logs={call.DebugOutput} callLogData={call} includeSysMessages={props.hasRole('ViewCallDebugOutput')} />
                            </td>
                        </tr>
                        {props.hasRole('SystemUserSummary') && call &&
                            <>
                            <tr className="system-owner-action">
                                <th>
                                    <div>
                                        Call ID (Internal)<br />
                                        Debug Output
                                        <button
                                            className="btn btn-link btn--faux-link"
                                            onClick={(e) => { e.preventDefault(); toggleDebugPrivateOutputHelp(); return false; }}>
                                            <i className="fa-solid fa-question-circle" id={"DebugPrivateOutputHelp"} />
                                        </button>
                                    </div>
                                    {showDebugPrivateOutputHelp &&
                                        <TeachingBubble
                                            target={'#DebugPrivateOutputHelp'}
                                            hasCondensedHeadline={true}
                                            onDismiss={dismissDebugPrivateOutputHelp}
                                            hasCloseIcon={true}
                                            isWide={true}
                                            closeButtonAriaLabel="Close"
                                            headline={"What Is Included in the Internal Debug Output?"}>
                                            <div>
                                                <p>This shows internal platform SIP messages between the Edge SBC and the direct routing SBC.</p>
                                            </div>
                                        </TeachingBubble>
                                    }
                                </th>
                                <td>
                                    <CallDebugOutput
                                        logs={call.DebugPrivateOutput}
                                        callLogData={call}
                                        includeSysMessages={props.hasRole('ViewCallDebugOutput')}
                                    />
                                </td>
                            </tr>
                            </>
                        }
                        {props.hasRole('SystemUserSummary') && call &&
                            <tr className="system-owner-action">
                                <th>Download Call Log JSON Files</th>
                                <td>
                                    <ul>
                                        <li>
                                            {call.DebugOutputURL
                                                ? <a href={call.DebugOutputURL}>Download State JSON Log</a>
                                                : <em>No JSON file available</em>
                                            }
                                        </li>
                                        <li>
                                            {call.DebugOutputPublicSIPURL ?
                                            <a href={call.DebugOutputPublicSIPURL}>Download Public SIP Log</a>
                                            : <em>No Public SIP JSON file available</em>
                                            }
                                        </li>
                                        <li>
                                            {call.DebugOutputPrivateSIPURL
                                                ?  <a href={call.DebugOutputPrivateSIPURL}>Download Private SIP Log</a>
                                                : <em>No Private SIP JSON file available</em>
                                            }
                                        </li>
                                    </ul>
                                    <em>Note: These links are time limited, refresh the page if they become unavailable.</em>
                                </td>
                            </tr>
                        }
                        {props.hasRole('SystemUserSummary') && call && call.Id.includes('demo_') && 
                            <tr className="system-owner-action">
                                <th>Demo Call List</th>
                                <td>
                                    { getExampleLogLists(props.account) }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {getCopyReportCallDialog()}
            </>
        );

    }
}
CallSingle.defaultProps = {
    callLogData: {},
    callLogUser: {},
    refetchCallData: () => { }
}
CallSingle.propTypes = {
    callLogUser: object,
    callLogData: object,
    refetchCallData: func.isRequired
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        services: state.services.rawList
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallSingle));

import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { withRouter } from 'react-router-dom';

var _ = require('lodash');


class ExpandingReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            data: [],
            expanded: {},
            filtered: [],
            page: 0,

            // Not specific to react-table
            hasRowExpanded: false
        }

        this.closeAllRows = this.closeAllRows.bind(this);
        this.forceTableUpdate = this.forceTableUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.location.pathname != this.props.location.pathname
            && self.state.filtered != []) {
            self.setState({ filtered: [] });
        }
        if (prevProps.location.pathname != this.props.location.pathname) {
            self.setState({ page: 0 });
        }
    }

    forceTableUpdate() {
        var self = this;
        self.refReactTable.fireFetchData();
    }

    closeAllRows = (e) => {

        if (e !== undefined) {
            e.preventDefault();
        }

        // Ditch all expanded rows...
        this.setState({
            expanded: {},
            hasRowExpanded: false
        });

        // Always fire off an update, as we would skip updates whilst the row was expanded
        this.props.userUpdateTrigger && this.props.userUpdateTrigger(true);
    }

    handleRowExpanded(newExpanded, index, event) {
        if (this.props.onExpandedChange) {
            this.props.onExpandedChange(newExpanded, index, event);
        } else {
            if (this.state.expanded[index] === true) {
                this.closeAllRows();
            } else {
                this.setState({
                    // we override newExpanded, keeping only current selected row expanded
                    expanded: { [index]: true },
                    hasRowExpanded: true
                });
            }
        }
    }

    serviceFilter(allServices, filter, onChange, useServiceId) {
        var opts;
        if (useServiceId) {
            opts = _.map(allServices, function (s) { return (<option key={s.Variant.ServiceId} value={s.Variant.ServiceId}>{s.Name}</option>) });
        } else {
            opts = _.map(allServices, function (s) { return (<option key={s.Id} value={s.Id}>{s.Name}</option>) });
        }

        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

    render() {
        var self = this;

        return (
            <ReactTable
                {...self.props}
                onPageChange={(newPage) => {
                    self.closeAllRows();
                    self.setState({ page: newPage });
                }}
                collapseOnSortingChange={true}
                collapseOnPageChange = {true}
                collapseOnDataChange={true}
                page={this.state.page}
                expanded={this.props.expanded ? this.props.expanded : this.state.expanded}
                resizable={self.props.resizable ? self.props.resizable : false}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered) => {
                    var filterOb = _.cloneDeep(filtered);

                    for (var i = 0; i < filterOb.length; i++) {
                        if (filterOb[i].value.trim() === "") {
                            delete filtered[i].value
                        }
                        else {
                            filtered[i].value = filtered[i].value.replace(/^\s*/, '');
                        }
                    }
                    self.setState({ filtered: filtered });
                }}
                onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
                minRows={0}
                showPageJump={self.props.showPageJump ? self.props.showPageJump : false}
                multiSort={self.props.multiSort ? self.props.multiSort : false}
                showPagination={self.props.showPagination ? self.props.showPagination : true}
                showPaginationTop={self.props.showPaginationTop ? self.props.showPaginationTop : false}
                showPaginationBottom={self.props.showPaginationBottom ? self.props.showPaginationBottom : true}
                pageSizeOptions={self.props.pageSizeOptions ? self.props.pageSizeOptions : [5, 10, 20, 25, 50, 100]}
                className={self.props.className ? self.props.className : "-striped -highlight"}
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                ref={(refReactTable) => { self.refReactTable = refReactTable; }}
                onFetchData={function (state, instance) {
                    if (self.props.updateData) {
                        self.props.updateData(state, instance);
                    }
                }}
            />
        );
    }
}
//withRouter is needed to gain access to the location prop which is used in these tables.
//forwardRef is used to send a ref of this component into the parent components, so that the forceUpdate method inside this class can be called inside the parent components directly

//both need to be wrapped around the exported class. However, this doesn't work, as withRouter doesn't pass on the refs that we need, therefore this extra helper function has been created to wrap 
//the class with both of the above and export the result
//https://stackoverflow.com/questions/52097564/forward-ref-through-react-routers-withrouter-hoc
//https://reactjs.org/docs/forwarding-refs.html

const withRouterForwardRef = Component => {
    const WithRouter = withRouter(({ forwardedRef, ...props }) => (
        <Component ref={forwardedRef} {...props} />
    ));

    return React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardedRef={ref} />
    ));
};

export default withRouterForwardRef(ExpandingReactTable);

import { oneOfType } from 'prop-types';
import { string, arrayOf, instanceOf, object} from 'prop-types';
import React from 'react';
import moment from 'moment';
import { CallLog } from '../../models/CallLog';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { getErrorHelp, getCallErrorHelp } from '../SIPErrors.js';
import { useState, Fragment } from 'react';
import './CallDebugOutput.css';

var parsip = require('parsip');
var _ = require('lodash');

export function CallDebugSummary(props) {
    const [showExtraErrorInfo, setShowExtraErrorInfo] = useState(false);

    if (!props || !props.logs || typeof props.logs === 'string' || props.logs.length === 0) {
        // Old style logs don't get the new summary
        return null;
    }

    var cd = props.callLogData;

    var durationStr = '';
    if (cd && cd.DurationMoment) {
        durationStr = cd.DurationMoment.humanize({precision: 3});
    }

    // 1) No-error/successful call.
    var msgType = MessageBarType.info; // info, success, warning, error
    if (cd.Disposition && (cd.Disposition === 'success' || cd.Dispotion === 'answered')) {
        msgType = MessageBarType.success;
    }

    var errorSummaryDetail = null;
    var errorExtraDetail = null;
    var callerServiceNameStr = '';
    if (props.callerServiceName) {
        callerServiceNameStr = ` (${props.callerServiceName})`;
    }
    var calleeServiceNameStr = '';
    if (props.calleeServiceName) {
        calleeServiceNameStr = ` (${props.calleeServiceName})`;
    }

    var summaryStrs = [];
    var includeSummaryStr = true;
    var firstSummaryStr = `Call from ${cd.neatCallerId}${callerServiceNameStr} to ${cd.neatCalleeId}${calleeServiceNameStr}`;

    // 2) Short call
    if (cd && cd.DurationSec && cd.DurationSec < 5) {
        summaryStrs = [`Short call duration of ${cd.DurationSec} seconds.`, `${firstSummaryStr}.`];
        includeSummaryStr = false;
        msgType = MessageBarType.warning;
    }

    const sipByTime = props.logs.filter(c => {
        if (c.type === 'SIP') {
            return c;
        }
        return null;
    }).sort((x, y) => {
        return x.time - y.time;
    }).map((x) => {
        return convertContentToSIP(x.content);
    });


    // 3) Cancelled call, or if not, who hung up?
    if (cd.Disposition && cd.Disposition === 'cancelled') {
        msgType = MessageBarType.info;
        firstSummaryStr += '.'; // No duration line.
        let cancelSummary = `Caller cancelled before the call was answered.`;

        // Look for a CANCEL, see if we can improve on our message
        let cancelMsg = _.find(sipByTime, function(sip) {
            // Some sort of client/server error?
            if (sip && sip.method === 'CANCEL') {
                return sip;
            }
        });

        if (cancelMsg) {
            if (cancelMsg.headers['Reason'] && cancelMsg.headers['Reason'][0].raw.toLowerCase().includes('call completed elsewhere')) {
                msgType = MessageBarType.success;
                cancelSummary = 'Call was answered elsewhere.';
            }
        }
        summaryStrs.push(cancelSummary);

    } else if (sipByTime.length) {
        let hangUpUser = null;

        // Look for a BYE
        let byeMsg = _.find(sipByTime, function(sip) {
            // Some sort of client/server error?
            if (sip && sip.method === 'BYE') {
                return sip;
            }
        });

        let inviteMsg = _.find(sipByTime, function(sip) {
            if (sip && sip.method === 'INVITE') {
                return sip;
            }
        });

        // What is the From address of the BYE?
        // Try match against our known calleer/callee
        if (byeMsg && byeMsg.from && byeMsg.from.uri && inviteMsg && inviteMsg.from && inviteMsg.from.uri) {
            let byeUser = byeMsg.from.uri._user;
            let inviteUser = inviteMsg.from.uri._user;
            //if the from user is the same on INVITE and BYE, then the callera is the hangup user
            if (byeUser == inviteUser) {
                hangUpUser = 'caller';
            } else {
                hangUpUser = 'recipient';
            }

        }

        // At this point we know whether to include duration in the very string or not
        if (hangUpUser && durationStr) {
            summaryStrs.push(`Call ended by ${hangUpUser} after ${durationStr}.`);
            firstSummaryStr += '.';
        } else if (durationStr) {
            firstSummaryStr += durationStr + '.';
        }

        // BYE message _could_ include an error
        if (byeMsg) {
            let sipErrorText = getSipErrorHelp(byeMsg, cd);
            if (sipErrorText) {
                msgType = MessageBarType.error;
                errorSummaryDetail = <span>{sipErrorText.summary}</span>
                if (sipErrorText.detail) {
                    errorExtraDetail = <div className="sip-error-detail">{sipErrorText.detail}</div>
                }
            }
        }
    }

    if (includeSummaryStr) {
        summaryStrs.unshift(firstSummaryStr);
    }


    // 4) Bad call...
    //    Start to look at SIP messages to consider root cause of any issues.
    if (cd.Disposition && cd.Disposition === 'failed') {
        msgType = MessageBarType.warning;

        // Find first 'bad' message
        let badMsg = _.find(sipByTime, function(sip) {
            // Some sort of client/server error?
            if (sip && sip.method === 'INVITE' && sip.status_code && sip.status_code >= 400) {
                return sip;
            }
        });

        if (badMsg) {
            msgType = MessageBarType.error;
            // 404 RNL?
            let sipErrorText = getSipErrorHelp(badMsg, cd);
            if (sipErrorText) {
                errorSummaryDetail = <span>{sipErrorText.summary}</span>
                if (sipErrorText.detail) {
                    errorExtraDetail = <div className="sip-error-detail">{sipErrorText.detail}</div>
                }
            }
        } else {
            // 4b) Missing response altogether?
            //     See if the call data gives enough information to explain the issue.
            let callErrorText = getCallDataErrorHelp(cd);
            if (callErrorText) {
                msgType = MessageBarType.error;
                errorSummaryDetail = <span>{callErrorText.summary}</span>
                if (callErrorText.detail) {
                    errorExtraDetail = <div className="sip-error-detail">{callErrorText.detail}</div>
                }
            }
        }
    }

    var toggle = null;
    if (errorExtraDetail) {
        toggle = (
            <>&nbsp;
            <button className="btn btn-xs btn-primary" onClick={() => setShowExtraErrorInfo(!showExtraErrorInfo)}>
                {showExtraErrorInfo ? "Hide Further Details":"Show Further Details"}
            </button>
            </>
        );
    }

    return (
        <MessageBar messageBarType={msgType} isMultiline={true}>
        {summaryStrs.join(' ')}&nbsp;{errorSummaryDetail}
		{toggle}
        {showExtraErrorInfo && errorExtraDetail ? <><br /><hr className="dark-hr" />{errorExtraDetail}</> : null}
        </MessageBar>
    );
}

const getSipErrorHelp = (sip, cd) => {
    if (!sip) { return; }

    var reason_hdr_val = sip.headers['Reason'] && sip.headers['Reason'][0].raw;

    var frompart1;
    if (cd) {
        frompart1 = cd.FromPart1;
    }

    var hasChallengeAuth = false;

    if (sip.headers['WWW-Authenticate'] || sip.headers['Proxy-Authenticate']) {
        hasChallengeAuth = true;
    }

    return getErrorHelp(
        sip.status_code,
        sip.reason_phrase,
        reason_hdr_val,
        sip.method,
        frompart1,
        hasChallengeAuth
    );
}

// Less preferable than getSipErrorHelp as only looks at overall call meta data
const getCallDataErrorHelp = (cd) => {
    return getCallErrorHelp(
        cd.FailureAdvice,
        cd.FromPart1
    );
}

const renderPacketSummaryLine = (sip, cd, includeExpandedMessages) => {
    if (!sip) {
        return null;
    }

    var extraErrorDetail = '';
    var sipErrorText = getSipErrorHelp(sip, cd);
    if (sipErrorText && includeExpandedMessages) {
        extraErrorDetail = (
            <MessageBar className="sip-warn-msg" messageBarType={MessageBarType.error} isMultiline={true}>
                {sipErrorText.summary}
                {sip && sip.headers && sip.headers.Reason
                    ? (<>
                        <br /><pre className='mb-error-pre'>Reason: {sip.headers['Reason'][0].raw}</pre>
                    </>)
                    : null
                }
            </MessageBar>
        );
    }

    var sipClasses = '';
    var hasSdp = sip && sip.body && sip.headers['Content-Type'] && sip.headers['Content-Type'][0].raw.includes('sdp');
    if (sip.status_code) {
        if (sip.status_code >= 200 && sip.status_code <= 299) {
            sipClasses = 'sip-success';
        } else if (sip.status_code >= 400 && sip.status_code <= 599) {
            sipClasses = 'sip-warn';
        }

        return (
            <span className={sipClasses}>
                <strong>{sip.status_code} {sip.reason_phrase} ({sip.method}) {hasSdp ? '(SDP)' : ''}</strong>
                {extraErrorDetail ? <><br />{extraErrorDetail}</> : ''}
            </span>
        );
    } else {
        var userPart = '';
        var showUri = '';

        if (sip.method === 'INVITE' && sip.ruri == null) {
            if (sip.data != null && sip.data.startsWith("INVITE tel:")) {
                var numberReg = /INVITE tel:(\+?[0-9]+) /i;
                var number = sip.data.match(numberReg)[1];
                showUri = number;
            }
        }
        else if (sip.method === 'INVITE' && sip.ruri._host) {
            if (sip.ruri._user) {
                userPart = sip.ruri._user + '@';
            }
            showUri = userPart + sip.ruri._host ;
        }

        return (
            <span className={sipClasses}>
                <strong>{sip.method}</strong> {showUri} {hasSdp ? '(SDP)' : ''}
                {extraErrorDetail ? <><br />{extraErrorDetail}</> : ''}
            </span>
        );
    }
}

const convertContentToSIP = (content) => {
    // Legacy message type can include a sent/received message
    // we need to remove this to then get SIP parsing
    if (!content) {
        return;
    }

    var lines = content.split('\n');
    if (lines && lines[0]) {
        if (lines[0].includes(' => ')) {
            lines.splice(0, 1);
        }

        // parsip doesn't like angle brackets in Request URI
        if (lines[0].includes('<') && lines[0].includes('>')) {
            let subStr = lines[0].substring(lines[0].indexOf('>') + 1, lines[0].lastIndexOf(' '));
            lines[0] = lines[0].replaceAll(subStr, '');
            lines[0] = lines[0].replaceAll('<', '').replaceAll('>', '');
        }

        // parsip insists on angle brackets in From and To headers
        let fromIdx = lines.findIndex(function(l) { return l.match(/from: /i)});
        let toIdx = lines.findIndex(function(l) { return l.match(/to: /i)});

        let uriLinesToFix = [];
        if (fromIdx > -1) { uriLinesToFix.push(fromIdx)}
        if (toIdx > -1) { uriLinesToFix.push(toIdx)}

        uriLinesToFix.forEach(function(vIdx) {
            if (!lines[vIdx].includes('<') && !lines[vIdx].includes('>')) {
                lines[vIdx] = lines[vIdx].replaceAll('\r', '');
                lines[vIdx] = '<' + lines[vIdx] + '>\r';
            }
        });
    }


    // Add missing \r\n
    let newLines = lines.join('\n');
    newLines = newLines.replace(/(\r)?\n/g, "\r\n");

    return parsip.getSIP(newLines);
}


export function CallDebugOutput(props) {

    //WARNING: Don't remove the below method.
    //Dates coming from sip logs need to pass through this, or they are incorrect
    const convertSecToMs = (secs = 0) => secs * 1000;

    const convertSecToTimeOnly = (secs = 0) => moment(convertSecToMs(secs)).format('HH:mm:ss');

    const [showSip, setShowSip] = useState([]);

    const DebugOutputSIP = (msg = new CallLog(), cd, frameNumber, includeExpandedMessages) => {
        let sip = convertContentToSIP(msg.content);

        let networkSummary = null;
        if (msg.hep) {
            networkSummary = (
                <p className='summary-p'>
                <i className='fa-solid fa-globe'></i>
                &nbsp;
                Source: <code>{msg.hep.srcip}:{msg.hep.srcport}</code>
                &nbsp;
                Destination: <code>{msg.hep.dstip}:{msg.hep.dstport}</code>
                &nbsp;
                (Protocol {msg.hep.proto.toUpperCase()})
                </p>
            );
        }
        let sipClassName = `sip-summary sip-${frameNumber + (showSip.indexOf(frameNumber) > -1 ? ' sip-summary--open' : ' sip-summary-closed')}` ;
        return (
            <>
                <div className={sipClassName} onClick={(e) => {
                    if(showSip.indexOf(frameNumber) > -1){
                        let index = showSip.indexOf(frameNumber);
                        showSip.splice(index, 1);
                        setShowSip([...showSip]);
                    } else {
                        let temp = [];
                        if(showSip.length > 0) {
                            temp = [...showSip, frameNumber];
                        } else {
                            temp = [frameNumber]
                        }
                        setShowSip([...temp]);
                    }
                }}>
                    <span>
                    {convertSecToTimeOnly(msg.time)}
                    &nbsp;
                    ({frameNumber.toString().padStart(4, '0')})
                    </span>

                    <span className="core-text-summary">
                    <strong>{renderPacketSummaryLine(sip, cd, includeExpandedMessages)}</strong>
                    </span>

                    <span className="toggle-icon">
                        {showSip.indexOf(frameNumber) > -1 ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
                    </span>
                </div>

                <div className="extra-sip-detail-container">
                {showSip.indexOf(frameNumber) > -1 ?
                    <>
                    {networkSummary}
                    <pre className="large-debug-text">{msg.content}</pre>
                    </>
                    : ''
                }
                </div>
            </>
        );
    }

    const renderDebugOutputLOG = (sip = new CallLog(), includeSysMessages) => {
        // {`, new state => ${sip.content.new_state_name}, accepted => ${sip.content.accepted_change === 1 ? 'Y' : 'N'} \n`}
        if (sip.content.new_state_name) {
            return (
                <span>
                    {convertSecToTimeOnly(sip.time)}&nbsp;
                    Status: <em>{sip.content.new_state_name ? sip.content.new_state_name : '-'}</em>
                </span>
            );
        } else if (typeof(sip.content) === "string" && includeSysMessages) {
            return (
                <span className="system-owner-action">
                    {convertSecToTimeOnly(sip.time)}&nbsp;
                    <i className="fa-solid fa-user-secret" title="System Owner Only"></i>
                    &nbsp;
                    Message: <em>{sip.content}</em>
                </span>
            );
        }
    }

    const renderCallDebugOutput = () => {
        if (typeof props.logs === 'string') {
            return (
                <pre className="large-debug-text">
                {props.logs}
                </pre>
            );
        }
        if (props.logs != null && props.logs.length > 0) {
            const outputByTime = props.logs.filter(c => {
                if (c.type !== 'CALL' && c.type !== 'CORRELATION') {
                    return c;
                }
                return null;
            }).sort((x, y) => {
                return x.time - y.time;
            });

            return (
                <ul className='call-status-list'>
                {renderOutputLines(outputByTime, props.callLogData, props.includeSysMessages, props.includeExpandedMessages)}
                </ul>
            );
        } else {
            return '[none]';
        }
    }

    const renderOutputLines = (logs = [], cd, includeSysMessages, includeExpandedMessages = 1) => {
        let frameNumber = 1;
        return (
            <>
                {logs.map((x, i) => {
                    let line = null;

                    switch (x.type) {
                        case 'INT':
                            // Internal message, shown for all
                            line = renderDebugOutputLOG(x, includeSysMessages);
                            break;
                        case 'LOG':
                            line = renderDebugOutputLOG(x, includeSysMessages);
                            break;
                        case 'SIP':
                            line = DebugOutputSIP(x, cd, frameNumber, includeExpandedMessages);
                            frameNumber++;
                            break;
                        default:
                            // no-op for types we don't recognise for now
                            break;
                    }

                    return (
                        <li key={i}>
                            {line}
                        </li>
                    );

                })}
            </>
        );
    }

    return renderCallDebugOutput();
}
CallDebugOutput.defaultProps = {
    logs: 'Debug output not yet available.',
};
CallDebugOutput.propTypes = {
    logs: oneOfType([
        arrayOf(instanceOf(object)),
        arrayOf(instanceOf(CallLog)),
        string,
        null
    ])
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { cmsGetPromise } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ExpandingReactTable from '../ExpandingReactTable';

var _ = require('lodash');

class AllocationLogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            pageCount: 0
        };

        this.updateData = this.updateData.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount() { }

    updateData(state, instance) {
        var self = this;
        var params = self.props.match.params;
        if (!params.accountId || !params.serviceName) {
            return;
        }

        self.setState({
            loading: true
        }, function () {
            var apiParams = {
                accountId: params.accountId,
                accountServiceId: params.serviceName,
                objectIds: [
                    {
                        type: 'services',
                        id: params.serviceName
                    },
                    {
                        type: 'allocationlogs',
                    }
                ],
                currentPage: 1,
                pageSize: 100
            };

            cmsGetPromise(apiParams).then(
                function (result) {
                    if (result.data) {
                        self.setState({
                            data: result.data.Results,
                            pageCount: result.data.PageCount,
                            loading: false
                        });
                    }
                }, function (err) {
                    self.setState({ loading: false });
                    toast.error("Unable to load logs: " + err);
                }
            );
        });
    }

    getColumns() {
        var columns = [];

        columns.push(
            {
                id: "createdon",
                Header: "Created",
                width: 200,
                accessor: log => log.CreatedOn,
                sortable: false,
                filterable: false
            }
        );

        columns.push({
            id: "message",
            Header: "Message",
            accessor: log => (<span class="large-debug-text">{log.Message.replace("\n", "<br />")}</span>),
            sortable: false,
            filterable: false,
            className: "large-debug-text"
        });

        return columns;
    }

    render() {
        var self = this;

        return (
            <>
                <h2>SBC Allocation Logs</h2>
                <hr />
                <ExpandingReactTable
                    pages={self.state.pageCount}
                    data={self.state.data}
                    loading={self.state.loading}
                    loadingText={"Loading..."}
                    noDataText={"No logs to show."}
                    updateData={self.updateData}
                    defaultSorted={[
                        {
                            id: "createdat",
                            desc: true
                        }
                    ]}
                    columns={self.getColumns()}
                />
            </>
        );
    }
}

export default connect(state => ({
    baseAccount: state.account.baseAccount,
    account: state.account.account,
    services: state.services.rawList
}))(withRouter(AllocationLogList));
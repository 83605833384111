import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import { cmsGetAdminUserRoleHistoryPromise } from '../CallMSAPI';

var moment = require('moment-timezone');

class AdminUserRoleHistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            loading: true,
            userRolesData: [],
            pageCount: -1
        }

        this.updateData = this.updateData.bind(this);
        this.userRoleHistoryTableRef = React.createRef();
    }

    updateData(state, _instance) {
        var self = this;

        var apiParams = {
            baseAccountId: self.props.baseAccount.Id,
            adminUserId: self.props.adminUserId
        };

        if (state['pageSize']) {
            apiParams['pageSize'] = state['pageSize'];
        }

        if (state.hasOwnProperty('page')) {
            // js from 0, api starts at 1
            apiParams['currentPage'] = state['page'] + 1;
        }

        cmsGetAdminUserRoleHistoryPromise(apiParams).then(function (res) {
            if (res && res.data && res.data.Results) {
                self.setState({
                    userRolesData: res.data.Results,
                    loading: false,
                    pageCount: res.data.PageCount
                });
            }
        }).catch(function (err) {
            toast.error(err);
        });

    }

    render() {
        var self = this;
        const userRolesData = this.state.userRolesData;

        return (
            <>
                <br />
                <h4> Admin User Roles Change Log </h4>
                <br />
                <div>
                    <ReactTable
                        data={userRolesData}
                        pages={this.state.pageCount}
                        resizable={false}
                        columns={[
                            {
                                id: 'alteredon',
                                Header: 'Altered On',
                                className: 'text-center',
                                accessor: function (d) {
                                    let t = moment.tz(d.AlteredOn, 'UTC');
                                    if (t.isValid()) {
                                        if (t.year() === moment().year()) {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                        } else {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                        }
                                    } else {
                                        return d.AlteredOn;
                                    }
                                },
                                sortable: false,
                                filterable: false,
                                width: 150
                            },
                            {
                                id: 'modifiedby',
                                Header: 'Modified By',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.ModifiedBy;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: 'adminroleid',
                                Header: 'Admin Role',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.AdminRole;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: 'status',
                                Header: 'Status',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.Status;
                                },
                                sortable: false,
                                filterable: false,
                            }
                        ]
                        }
                        minRows={0}
                        showPageJump={false}
                        updateData={self.updateData}
                        multiSort={false}
                        loading={this.state.loading}
                        loadingText={'Loading...'}
                        noDataText={"You don't have any Admin User Roles Change History."}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        pageSizeOptions={[5]}
                        pageSize={5}
                        className='-striped -highlight'
                        // Tell react-table that we'll be sorting pagination and sorting via server side
                        manual
                        // Important to allow manual updating
                        ref={this.userRoleHistoryTableRef}
                        onFetchData={function (state, instance) {
                            self.updateData(state, instance);
                        }}
                    />
                </div>
                <br />
                <br />
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserRoleHistoryTable);
import React, { Component } from 'react';
import {
    cmsGetPromise,
    cmsTempAuthRedirect,
    getErrMsg,
    getBaseUrl
} from '../..//CallMSAPI.js';
import { toast } from 'react-toastify';
import ExpandingReactTable from '../../ExpandingReactTable';
import { connect } from 'react-redux';

var moment = require('moment-timezone');

class BillingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},

        };

        this.updateData         = this.updateData.bind(this);
        this.getColumns         = this.getColumns.bind(this);
        this.handleBillRequest = this.handleBillRequest.bind(this);
    }

    updateData(state, instance) {
        var self = this;

        self.setState({
            loading: true
        }, function () {
            var apiParams = {
                accountId: this.props.contractAccount.Id,
                objectType: 'billingstatements',
            };

            if (state.sorted.length > 0) {
                apiParams['SortBy'] = 'BillDate';

                if (state.sorted[0].desc === false) {
                    apiParams['SortDirection'] = 'Ascending';
                } else {
                    apiParams['SortDirection'] = 'Descending';
                }
            }

            // Handle pagination
            if (state['pageSize']) {
                apiParams['pageSize'] = state['pageSize'];
            }

            if (state['page']) {
                // JS from 0, API starts at 1
                apiParams['currentPage'] = state['page'] + 1;
            }

            cmsGetPromise(apiParams).then(
                function (result) {
                    if (result.data) {
                        self.setState({
                            data: result.data.Results,
                            pageCount: result.data.PageCount,
                            loading: false
                        });
                    }
                }, function (err) {
                    self.setState({ loading: false });
                    toast.error("Unable to request billing data: " + getErrMsg(err));
                }
            );
        });
    }

    handleBillRequest(e, bill, isSnapshot, isSummary) {
        var self = this;
        // Makes a temp token (for query string) then direct the user straight to download URL
        // GET / v1 / accounts / { AccountId } / billingstatements / { BillingStatementId }
        // SubscriptionSnapshot true/false, if true excludes arrears
        var p = cmsTempAuthRedirect(self.props.contractAccount.Id);
        p.then(function (data) {
            var tempToken = data.UriReadyToken;
            var url = getBaseUrl() + '/accounts/' + self.props.contractAccount.Id + '/billingstatements/' + bill.Id;
            if (isSummary) {
                url += '/summary';
            }
            url += '?tempToken=' + tempToken;
            url += '&OwnerAccountId=' + self.props.baseAccount.Id;
            url += '&SubscriptionSnapshot=' + (isSnapshot ? "true" : "false");
            url += '&format=csv'; // Ensure result comes in as CSV
            window.location = url;
        }, function (err) {
            toast.error("Unable to generate token for billing download URL: " + getErrMsg(err));
        })
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "billdate",
                Header: <p className="text-left">Bill Date</p>,
                accessor: function (bill) {
                    var t = moment.tz(bill.BillDate, "UTC");
                    return (
                        <>
                        <span>{t.format("MMMM YYYY")}</span>
                        {bill.HasMultipleCurrencies ? <i className="fa-solid fa-triangle-exclamation" title="Bill has multiple currencies"></i> : null}
                        </>
                    );
                },
                maxWidth: 200,
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                className: "download-bill-col text-centre row downloadBilling-button-wrapper",
                id: "subscriptionamount",
                Header: <p className="text-left">Subscription Value</p>,
                accessor: function (bill) {
                    return (
                        <>
                            <div className=' col-sm-4 col-xs-12 text-right'>{bill.PrimaryCurrencyCode + ' ' + bill.PrimaryCurrencyAdvanceAmount.toFixed(2)}</div>
                            <div className="downloadBilling-button col-sm-8 col-xs-12 " >
                                <button className="btn" >
                                    <i className="fa-solid fa-download" style={{ color: '#337ab7' }}></i>
                                </button>
                                <div className="downloadBilling-dropdown">
                                    <button title={"Download subscription detail"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, true, false)}>Detail</button>
                                </div>
                            </div>
                        </>
                    );
                },
                maxWidth: 400,
                sortable: false,
                filterable: false
            }
        );

        columns.push(
            {
                className: "download-bill-col text-left row downloadBilling-button-wrapper",
                id: "advanceAmount",
                Header: <p className="col-sm-4 col-xs-12 text-centre">Total Bill</p>,
                accessor: function (bill) {
                    var figure = bill.PrimaryCurrencyAdvanceAmount + bill.PrimaryCurrencyArrearsAmount;
                    return (
                        <>
                            <div className='col-sm-4 col-xs-12 text-right'>{bill.PrimaryCurrencyCode + ' ' + figure.toFixed(2)}</div>
                            <div className="downloadBilling-button col-sm-8 col-xs-12 " >
                                <button className="btn" >
                                    <i className="fa-solid fa-download" style={{ color: '#337ab7'}}></i>
                                </button>
                                <div className="downloadBilling-dropdown">
                                    <button title={"Download detail bill"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, false, false)}>Detail</button>
                                    <button title={"Download summary bill"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, false, true)}>Summary</button>
                                </div>
                            </div>
                        </>
                    );
                },
                sortable: false,
                filterable: false
            }
        );

        return columns;
    }

    render() {
        var self = this;

        return (
            <ExpandingReactTable
                pages={this.state.pageCount}
                data={self.state.data}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"No billing data available."}
                updateData={self.updateData}
                defaultSorted={[
                    {
                        id: "started",
                        desc: true
                    }
                ]}
                columns={self.getColumns()}
            />
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingTable);
